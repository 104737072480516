angular.module('mosaik.controllers')
  .controller('groupContentSelectContentController',
    ['$scope', '$translate', 'datatablesHelper', '$timeout', 'contentService', 'groupContentFactory',
      function ($scope, $translate, datatablesHelper, $timeout, contentService, groupContentFactory) {
        $scope.contents = []
        $scope.defaultContentid
        $scope.contentDisabled
        $scope.showAlert

        // load the contents in the list
        contentService.contentsForList({ activeState: 'active', visible: true }).then(function (data) {
          $scope.defaultContentid = groupContentFactory.content.id

          if (groupContentFactory.action === 'edit' && groupContentFactory.content.inProgress) {
            $scope.contentDisabled = $scope.showAlert = true
            $scope.messageAlert = $translate.instant('CannotEditContent')
          }

          $scope.contents = data

          $scope.$on('select-modified', function (event, data) {
            if (data.id !== 'contents-selectpicker') {
              return
            }
            if (!$scope.contentDisabled && groupContentFactory.content.id !== data.selected.id) {
              groupContentFactory.content.id = data.selected.id
            }
          })
        })
      }
    ]
  )
