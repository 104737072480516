angular.module('mosaik.services')
  .factory('inviteService',
    ['$window', '$http', '$state', '$translate', 'notificationHelper', 'httpService', 'utilService', 'sessionStateService',
      function ($window, $http, $state, $translate, notificationHelper, httpService, utilService, sessionStateService) {
        const self = {
          setup: () => httpService.get('/admin-api/invite/setup'),
          create: (format, data, resolve) => {
            $http.put('/admin-api/invite/' + format + '?orgid=' + data.orgid, JSON.stringify(data), format === 'pdf' ? { responseType: 'arraybuffer' } : {})
              .then(response => resolve(response), error => {
                if (format === 'pdf') {
                  // the error.data contains the message but is an arraybuffer. Should be converted to JSON prior to continue
                  error.data = utilService.arrayBufferToJSON(error.data)
                }
                resolve(error)
              })
          },
          isExpired: code => (code.expiresAt !== null && $window.moment(code.expiresAt).isBefore()),
          isUsed: code => (code.useCounter >= code.useMax),
          isDeactivated: code => !self.isUsed(code) && (code.orgActive === 0),
          isStatusValid: code => (!isUsed(code) && !isExpired(code)),
          inputSwitchOff: () => {
            const switches = angular.element('input[type="checkbox"]')
            switches.bootstrapSwitch('disabled', false)
            switches.bootstrapSwitch('state', false)
            switches.bootstrapSwitch('disabled', true)
          },
          inputSwitchOn: () => {
            const switches = angular.element('input[type="checkbox"]')
            switches.bootstrapSwitch('disabled', false)
            switches.bootstrapSwitch('state', true)
            switches.bootstrapSwitch('disabled', true)
          },
          getDeliveryStates: () => {
            return [
              {
                id: 'sent',
                name: $translate.instant('Sent'),
                label: 'success'
              },
              {
                id: 'notSent',
                name: $translate.instant('NotSent'),
                label: 'default'
              },
              {
                id: 'deliveryFailed',
                name: $translate.instant('DeliveryFailed'),
                label: 'danger'
              }
            ]
          },

          remove: (selectedRows, dtEditor) => {
            const indexes = selectedRows.indexes()
            $window.Swal.fire({
              title: $translate.instant('DeleteInviteTitle', { count: indexes.length }),
              text: $translate.instant('DeleteText'),
              confirmButtonText: $translate.instant('Yes'),
              cancelButtonText: $translate.instant('No'),
              icon: 'warning',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                return new Promise(resolve => {
                  dtEditor
                    .remove(selectedRows.indexes(), false)
                    .submit(response => resolve(response), error => resolve({ error: notificationHelper.errorMessageFrom(error), value: error }))
                })
              }
            }).then(result => {
                if (!result.value) { // dismiss
                  return
                }
                if (result.value.error) {
                  return notificationHelper.swalError(result.value.error, result.value.value)
                }
                const successMessage = result.value.acceptedCount ? $translate.instant('DeleteInviteSuccess', { acceptedCount: result.value.acceptedCount }) : ''
                const rejectedMessage = result.value.rejectedCount ? $translate.instant('DeleteInviteError', { rejectedCount: result.value.rejectedCount }) : ''

                return $window.Swal.fire({
                  title: $translate.instant('Result'),
                  html: successMessage + (rejectedMessage && successMessage ? '<br><br>' : '') + rejectedMessage,
                  icon: result.value.acceptedCount ? 'success' : 'error'
                })
              })
          },

          showDownload: (response, callbackSuccess) => {
            if ((response.status === 200 || response.status === 201) && response.data) {
              const blob = new Blob([response.data], { type: 'application/pdf' })
              const filename = 'invitations.pdf'
              const url = $window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = filename
              a.click()
              const link = '<p><a href="' + url + '" target="_blank"><h4><i class="fa fa-file-pdf-o fa-3x"></i><br>Invitations</h4></a></p>'
              const html = $translate.instant('PDFSuccessMessage', { link })

              return $window.Swal.fire({
                title: $translate.instant('Success'),
                html,
                icon: 'success',
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: $translate.instant('SendSuccessCancel'),
                confirmButtonText: $translate.instant('PDFSuccessConfirm')
              }).then(success => {
                if (success.dismiss === 'cancel') return $state.go('dashboardInviteStatus', {}, { reload: 'secure' })
                if (callbackSuccess) callbackSuccess()
              })
            }
            // check for a valid session
            return $window.Swal.fire($translate.instant('CreatePDFError'), notificationHelper.errorMessageFrom(response), 'error').then(() => sessionStateService.validSessionOrRedirect())
          }
        }
        return self
      }]
  )