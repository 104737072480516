angular.module('mosaik.controllers')
  .controller('sessionCompletedController',
    ['$scope', '$window', '$state', 'sessionState', 'groups', 'roles', 'tags', 'organizations', 'contents', 'categories', '$translate', '$timeout', '$interpolate', 'datatablesHelper', 'utilService', 'timeService', 'contentService', 'certificateService',
      function ($scope, $window, $state, sessionState, groups, roles, tags, organizations, contents, categories, $translate, $timeout, $interpolate, datatablesHelper, utilService, timeService, contentService, certificateService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-session-completed'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/session',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.queryDefault = {
          dateRange: timeService.last30Days(),
          state: 'completed',
          tagFilterMethod: 'or'
        }
        $scope.query = {
          dateRange: {
            start: null,
            end: null
          },
          tagFilterMethod: '',
          tags: [],
          organizations: [],
          categories: []
        }
        // data
        $scope.ranges = timeService.sessionRanges()
        $scope.tags = tags
        $scope.groups = groups
        const groupsAsObjects = utilService.mapObjectsByKey(groups)
        $scope.roles = roles
        $scope.organizations = organizations
        const organizationsAsObjects = utilService.mapObjectsByKey(organizations)
        $scope.contents = contents
        const contentsAsObjects = utilService.mapObjectsByKey(contents)
        $scope.categories = categories
        const categoriesAsObjects = utilService.mapObjectsByKey(categories)
        categories = utilService.mapObjectsByKey(categories)
        const tagsAsObjects = utilService.mapObjectsByKey(tags)
        // Editor
        // DT table
        $scope.table
        const CERTIFICATE_LIMIT = 50

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', initializeTable))

        function initializeTable() {
          const extraButtons = [{
            extend: 'edit',
            text: $translate.instant('ShowCertificate'),
            className: 'show-certificate',
            action: (e, dt) => downloadCertificate(dt.rows({ selected: true }))
          }, {
            extend: 'edit',
            text: $translate.instant('ShowInteractions'),
            className: 'show-interactions',
            action: (e, dt) => showInteractions(dt.rows({ selected: true }))
          }, {
            extend: 'edit',
            text: $translate.instant('ShowQuiz'),
            className: 'show-quiz',
            action: (e, dt) => showQuiz(dt.rows({ selected: true }))
          }]
          const exportOptions = {
            columns: sessionState.isRoot ?
              [0, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21] : [0, 2, 3, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
          }
          const exportMessage = $translate.instant('CompletedContent')
          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            exportOptions,
            exportMessage,
            filteringInput: false,
            extraButtons,
            extraDTOptions: {
              pageLength: 10, // force default page length to reset to smallest possible
              serverSide: true
            },
            lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
            excelForceStrCols: sessionState.isRoot ? ['E', 'F', 'K'] : ['G']
          }), {
            columns: [
              { // 0 REPORT-VISIBLE
                data: 'updatedAt',
                orderData: 1,
                render: datatablesHelper.renderDateFromNow
              },
              { // 1
                data: 'updatedAt',
                visible: false,
                className: 'never',
                searchable: false,
                render: datatablesHelper.renderDateTimeReport
              },
              { // 2 REPORT-VISIBLE
                data: 'startedAt',
                responsivePriority: 10001,
                searchable: false,
                render: datatablesHelper.renderDateTimeHuman
              },
              { // 3 REPORT-VISIBLE - **ORDERING COLUMN**
                data: 'completedAt',
                orderData: 4,
                render: datatablesHelper.renderDateTimeHuman
              },
              { // 4 - used to order column 3
                data: 'completedAt',
                visible: false,
                className: 'never',
                searchable: false,
                render: datatablesHelper.renderDateTimeReport
              },

              { // 5 REPORT-VISIBLE (root)
                data: 'sessionOrgName',
                visible: sessionState.isRoot,
                className: (!sessionState.isRoot ? 'never' : ''),
                render: (data, type, full) => {
                  if (!sessionState.isRoot) return ''
                  return type === 'export' ? data : data + ' <small class="text-id">' + full.sessionInternalOrgid + '<small>'
                }
              },
              { // 6 Export visible (root)
                data: 'sessionInternalOrgid',
                visible: false,
                className: 'never'
              },
              { // 7 Export visible (root)
                data: 'sessionBusinessNumber',
                visible: false,
                className: 'never'
              },
              { // 8 REPORT-VISIBLE (root) Org groups, from organization id (as tokenized id, ex: '1,2,3,5')
                data: 'sessionOrgid',
                visible: sessionState.isRoot,
                className: (!sessionState.isRoot ? 'never' : ''),
                searchable: false,
                orderable: false,
                render: (data, type, full, meta) => {
                  var org = organizationsAsObjects[data] // find the org object using the sessionOrgid
                  if (!sessionState.isRoot || !org) {
                    return ''
                  }
                  return datatablesHelper.renderGroups(utilService.toArrayById(org.groupids), type, full, meta, groupsAsObjects)
                }
              },
              { // 9
                data: 'tagids',
                searchable: false,
                orderable: false,
                render: (data, type, full, meta) => datatablesHelper.renderTags(utilService.toArrayById(data), type, full, meta, tagsAsObjects)
              },
              {
                data: 'id', // sessionid
                visible: false
              },
              {
                data: 'userid',
                visible: false
              },
              {
                data: 'internalUserid',
                visible: false
              },
              {
                data: 'displayName',
                render: (data, type, full, meta) => {
                  result = datatablesHelper.sanitizeEscape(data)
                  if (full.orgid !== full.sessionOrgid) {
                    result += ' <br><i><small class="text-id">' + $translate.instant('UnderAnotherOrg', { orgid: full.sessionOrgid }) + '<small></i>'
                  }
                  return result
                }
              },
              {
                data: 'email',
                render: datatablesHelper.sanitizeEscape,
                searchable: false,
                orderable: false,
                visible: false
              },
              { // categories from contentid
                data: 'contentid',
                visible: false,
                searchable: false,
                orderable: false,
                render: (data, type, full, meta) => {
                  const content = contentsAsObjects[data]
                  const categoryids = content ? content.categoryids : ''
                  return datatablesHelper.renderCategories(categoryids, type, full, meta, categoriesAsObjects)
                }
              },
              {
                data: 'contentid',
                searchable: false,
                orderable: false,
                visible: false
              },
              {
                data: 'locale',
                searchable: false,
                orderable: false,
                visible: false
              },
              {
                // content Title
                data: (row, type) => {
                  const content = contentsAsObjects[row.contentid]
                  if (!content) {
                    // unknown content (the admin has no access to this content, can be private content from another org)
                    return '<i class="text-id">' + $translate.instant('ContentFromAnotherOrg', { contentid: row.contentid }) + '</i>'
                  }
                  return content.titleText
                },
                searchable: false,
                orderable: false
              },
              {
                data: 'scoreScaled',
                render: (data) => data !== null ? Math.round(data * 100) : ''
              },
              {
                data: 'timeInMillisecond',
                render: (data) => (data / 1000 / 60).toFixed(1)
              },
              { // givesCertificate
                searchable: false,
                orderable: false,
                data: (row, type, full, meta) => {
                  const content = contentsAsObjects[row.contentid]
                  return content && content.givesCertificate ? datatablesHelper.renderYesOnly(true, type, full, meta, '', '', 'certificate') : ''
                }
              },
              { // render actions menu
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  const content = contentsAsObjects[row.contentid] || {}
                  const showMenu = content.id !== undefined
                  const givesCertificate = showMenu && content.givesCertificate
                  if (!showMenu) {
                    return ''
                  }
                  const template = `
                    <div class="dt-buttons btn-group dt-button-actions dropdown">
                      <button type="button" class="btn btn-defaut btn-icon btn-left dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i> 
                      </button> 
                      <ul class="dropdown-menu dropdown-menu-right">
                        ${givesCertificate ? `
                        <li class="dt-button">
                          <a id="show-certificate" data-id="${row.id}">${$translate.instant('ShowCertificate', { count: 1 })}</a>
                        </li>
                        <li role="separator" class="divider"></li>
                        ` : ''}
                        <li class="dt-button"><a id="show-interactions" data-id="${row.id}">{{"ShowInteractions" | translate}}</a></li>
                        <li class="dt-button"><a id="show-quiz" data-id="${row.id}">{{"ShowQuiz" | translate}}</a></li>
                      </ul>
                    </div>`
                  return ($interpolate(template)($scope))
                }
              }
            ],
            select: true,
            order: [3, 'desc']
          })
          datatablesHelper.createAndSetTable(datatableParams)

          // On table select
          $scope.table.on('select.dt.DT deselect.dt.DT', () => {
            const data = $scope.table.rows({ selected: true }).data()
            const selected = data.any()
            const hasSelected = data.length > 0
            const singleSelected = data.length === 1
            const certificateLimitSelected = data.length <= CERTIFICATE_LIMIT
            const canEdit = !data.toArray().some(one => {
              let content = contentsAsObjects[one.contentid]
              return !content
            })
            const canShowCertificate = !data.toArray().some(one => {
              let content = contentsAsObjects[one.contentid]
              return !content || !content.givesCertificate
            })
            $scope.table.buttons(['.show-certificate']).enable(hasSelected && canEdit && canShowCertificate && certificateLimitSelected)
            $scope.table.buttons(['.show-interactions', '.show-quiz']).enable(hasSelected && singleSelected && canEdit)
            // plural button text
            $scope.table.buttons(['.show-certificate']).text($translate.instant('ShowCertificate', { count: data.length }))
          })
        }

        function downloadCertificate(selected) {
          const data = selected.data()
          const userids = utilService.ids(data.toArray(), 'userid').join()
          const sessionids = utilService.ids(data.toArray()).join()

          certificateService.get(userids, sessionids)
        }

        function showInteractions(selected) {
          const data = selected.data()[0]
          const content = contentsAsObjects[data.contentid] || {}
          $state.go('dashboardInteraction', {
            sessionid: data.id,
            title: content.titleText,
            displayName: data.displayName,
            date: data.completedAt,
            previousState: 'dashboardSessionCompleted'
          }, { reload: 'secure' })
        }

        function showQuiz(selected) {
          const data = selected.data()[0]

          contentService.setQuizViewer(data.contentid, data.locale)
            .then(response => {
              if (!response.success) {
                return $window.Swal.fire({
                  title: $translate.instant('NoQuiz'),
                  icon: 'warning'
                })
              }
              // open the viewer
              contentService.showQuizViewer()
            })
        }

        function doAction(scope, fnAction) {
          datatablesHelper.selectClosestRow($scope.table, datatablesHelper.getParentDiv(scope))
          fnAction($scope.table.rows({ selected: true }))
        }

        tableElement.on('click', '#show-certificate:not(.disabled)', event => doAction(event.target, downloadCertificate))
        tableElement.on('click', '#show-interactions:not(.disabled)', event => doAction(event.target, showInteractions))
        tableElement.on('click', '#show-quiz:not(.disabled)', event => doAction(event.target, showQuiz))
      }
    ])
