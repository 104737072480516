.factory('groupContentFactory', function() { 
  return { 
      action: '',
      content: {},
      useridParticipants: []
  }
})


.directive('clickWizardGroupContent',
  ['$window', '$http', '$interpolate', '$compile', '$timeout', '$translate', 'sessionStateService', 'colorService', 'groupContentFactory', 'sessionParticipantService',
    function ($window, $http, $interpolate, $compile, $timeout, $translate, sessionStateService, colorService, groupContentFactory, sessionParticipantService) {
      return {
        restrict: 'A',
        scope: {
          action: "@",
          content: "="
        },
        link: function (scope, element, attrs) {
          element.on('click', function () {

            // check for valid session first
            sessionStateService.validSessionOrRedirect()
              .then(function (sessionState) {
                if (!sessionState) {
                  return // make sure to stop execution on invalid session
                }
                var steps = ['1', '2', '3']
                var currentStep = 0
                groupContentFactory.action = scope.action
                groupContentFactory.participants = []

                var swalQueue = $window.Swal.mixin({
                  showCancelButton: true,
                  showCloseButton: true,
                  allowOutsideClick: false,
                  reverseButtons: true,
                  heightAuto: false,
                  backdrop: colorService.shade(colorService.rgb2hex(angular.element('body').css('background-color')), -10),
                  showClass: {
                    popup: 'animate__animated animate__fadeIn animate__faster',
                    backdrop: 'swal2-noanimation'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOut animate__faster'
                  },
                  customClass: {
                    container: '',
                    popup: 'swal2-popup-full-width',
                    content: 'swal2-content-full-width',
                    confirmButton: 'swal2-button-fix-width',
                    cancelButton: 'swal2-button-fix-width',
                    denyButton: 'swal2-button-fix-width'
                  },
                  progressSteps: steps,
                  didClose: function () {
                    console.log('did close')
                  }
                })

                var initPromise = new Promise(function (resolve) {
                  if (scope.action === 'create') {
                    groupContentFactory.content = {}
                    return resolve()               
                  }
                  
                  // edit or duplicate
                  groupContentFactory.content = {
                    id: scope.content.id,
                    locale: scope.content.locale
                  }

                  if (scope.action === 'edit') {
                    groupContentFactory.content.sessionid = scope.content.sessionid
                  }

                  // and get the participants list
                  sessionParticipantService.list(groupContentFactory.content.sessionid)
                    .then(function (participants) {
                      groupContentFactory.participants = participants
                      resolve()
                    })
                })

                // start the edit process
                initPromise.then(function () {
                  $timeout(function () {
                    goToNext()
                  })
                })

                function fireStep1SelectContentAndCreate() {
                  swalQueue.fire({
                    title: $translate.instant(scope.action === 'edit' ? 'GroupContentEditTitle' : 'GroupContentCreateTitle'),
                    icon: 'question',
                    iconHtml: '<i class="fa fa-users" aria-hidden="true"></i>',
                    confirmButtonText: $translate.instant('ContinueAndSelectUsers'),
                    showCancelButton: currentStep > 0,
                    currentProgressStep: currentStep,
                    hideClass: {
                      popup: 'animate__animated animate__fadeOut animate__faster',
                      backdrop: 'swal2-noanimation'
                    },
                    willOpen: function () {
                      // compile the template for the popup
                      var template = '<ng-include src="\'/public/views/library/group-contents-create/select-content.html\'"></ng-inlude>'
                      // Using current scope, to pass action and content scope params.
                      var compiled = ($compile(template)(scope))
                      // apply the template to the popup to load the controller
                      var content = $window.Swal.getContent()
                      content.append(compiled[0])
                    }
                  }).then(onNext)
                }

                function fireStep2SelectParticipants() {
                  swalQueue.fire({
                    title: 'Question 2',
                    cancelButtonText: $translate.instant('ReturnToSelectContent'),
                    confirmButtonText: $translate.instant('ContinueToSummary'),
                    hideClass: {
                      popup: 'animate__animated animate__fadeOut animate__faster',
                      backdrop: 'swal2-noanimation'
                    },
                    currentProgressStep: currentStep
                  }).then(onNext)
                }

                function fireStep3ShowSummary() {
                  swalQueue.fire({
                    title: 'Question 3',
                    cancelButtonText: $translate.instant('ReturnToSelectUsers'),
                    confirmButtonText: $translate.instant('SaveAndClose'),
                    currentProgressStep: currentStep
                  }).then(onNext)
                }


                function onNext(result) {
                  if (result.dismiss === $window.Swal.DismissReason.close || result.dismiss === $window.Swal.DismissReason.esc) {
                    scope.$broadcast('group-content-edit-closed')
                    return
                  }
                  if (result.value) {
                    currentStep++
                  }
                  if (result.dismiss === $window.Swal.DismissReason.cancel) {
                    currentStep--
                  }
                  $timeout(function() {
                    goToNext()
                  })
                }

                function goToNext() {
                  switch (currentStep) {
                    case 0:
                      fireStep1SelectContentAndCreate();
                      break;
                    case 1:
                      fireStep2SelectParticipants();
                      break;
                    case 2:
                      fireStep3ShowSummary();
                      break;
                  }
                }
              })
          })
        }
      }
    }
  ])