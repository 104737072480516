angular.module('mosaik.services')
  .factory('certificateService',
    ['$window', '$translate', 'httpService', 'translationService', 'fileService',
      function ($window, $translate, httpService, translationService, fileService) {
        return {
          get: (userids, sessionids) => {
            const getFile = (locale) => {

              const swal = $window.Swal.fire({
                title: $translate.instant('Processing'),
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => $window.Swal.showLoading()
              })

              return new Promise((resolve, reject) => {
                httpService.custom({
                  url: `/${userids === 'me' ? 'api' : 'admin-api'}/user/${userids}/certificate/${sessionids}?locale=${locale}`,
                  method: 'GET',
                  responseType: 'blob'
                })
                  .then(resolve)
                  .catch(reject)
                  .finally(() => swal.close())
              })
            }

            return translationService.selectLanguage('LocaleQuestionCertificate')
              .then(locale => {
                if (!locale) return
                getFile(locale)
                  .then(response => {
                    if (!response) return
                    fileService.showDownload(response, 'FileReady', 'pdf')
                  })
                  .catch(error => {
                    notificationHelper.failureFromBlob(error)
                  })
              })
          }
        }
      }]
  )