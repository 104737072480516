angular.module('mosaik.services')
  .factory('sessionParticipantService',
    ['httpService',
      function (httpService) {
        const self = {
          list: function (sessionid) {
            const url = '/admin-api/session-participant/' + sessionid
            return httpService.get(url)
          }
        }
        return self
      }]
  )