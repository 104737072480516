angular.module('mosaik.services')
  .factory('contentService',
    ['$window', 'sessionStateService', 'dashboardService', '$translate', 'translationService', 'iconService', 'httpService', 'modalService',
      function ($window, sessionStateService, dashboardService, $translate, translationService, iconService, httpService, modalService) {

        function _getAndSort(url, noCache) {
          const collator = new Intl.Collator(translationService.getLocaleShort(), { numeric: true, sensitivity: 'base' })

          return httpService.get(url, {
            noCache,
            noCacheOnEmpty: true,
            dataParser: data => {
              // Sort the list 
              data.sort((a, b) => collator.compare(a.titleText, b.titleText))
              return data
            }
          })
        }

        return {
          /**
           * @param params Object
           * @param params.orgid Defaults to connected user orgid or "all" for root
           * @param params.activeState Defaults to "any", possible values: active, inactive
           * @param params.priorityState Defaults to "any", possible values: priority, nonPriority
           * @param params.visible Defaults to null (any), possible values: true or false 
           * @param params.withGroupContents Defaults to false (excluded by default)
           * @param params.withNonGroupContents Defaults to true (included by default)
           */
          contentsForList: function (params = {}) {
            return sessionStateService.lastSession()
              .then(function (currentState) {
                const queryParams = {
                  orgid: currentState.orgidSelector,
                  activeState: 'active'
                }
                const url = dashboardService.ajaxUrl('/admin-api/contentsForList', angular.extend(queryParams, params))
                return _getAndSort(url, params.noCache)
              })
          },

          format: function (content) {
            return (!content.active ? '<i>' : '') + content.titleText +
              (!content.active ? '</i>&nbsp;&nbsp;<span class="label-mosaik label-mosaik-danger">' + $translate.instant('Inactive') + '</span>' : '')
          },

          formatAsText: function (content) {
            return content.titleText + (!content.active ? ' (' + $translate.instant('Inactive') + ')' : '')
          },

          redactorParamsForTitle: function () {
            return {
              iconsOptions: iconService.getAsPickerOptions(),
              lang: translationService.getLocaleShort(),
              buttons: ['html', 'formatting', 'bold', 'italic'],
              plugins: ['source', 'fontcolor', 'iconpicker', 'subscript', 'superscript', 'small', 'specialchars']
            }
          },
          redactorParamsForDescription: function () {
            return {
              iconsOptions: iconService.getAsPickerOptions(),
              lang: translationService.getLocaleShort(),
              plugins: ['source', 'inlinestyle', 'table', 'alignment', 'iconpicker', 'subscript', 'superscript', 'small', 'specialchars']
            }
          },

          setQuizViewer: (contentid, locale) => httpService.post('/admin-api/quiz-viewer', { contentid, locale }),
          showQuizViewer: () => modalService.popupFrame('/admin-api/quiz-viewer', { showPrint: true }),

          getXapiPackage: (contentid, orgid) => {
            return $window.Swal.fire({
              title: $translate.instant('XApiExitToQst'),
              text: $translate.instant('XApiExitToText'),
              icon: 'info',
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: $translate.instant('Yes'),
              cancelButtonText: $translate.instant('No')
            }).then(result => {
              if (result.isDismissed) {
                if (result.dismiss === 'cancel') return get()
                return
              }
              return $window.Swal.fire({
                input: 'url',
                title: $translate.instant('EnterExitToURL'),
                inputPlaceholder: 'URL',
                showCloseButton: true,
                icon: 'info'
              }).then(result => {
                if (result.isDismissed) return
                return get(result.value)
              })
            })

            function get(exitTo) {
              return httpService.custom({
                url: `/admin-api/content/xapiPackage/${contentid}/${orgid}?exitTo=${exitTo || ''}`,
                method: 'GET',
                responseType: 'blob'
              })
            }
          }
        }
      }]
  )