angular.module('mosaik.controllers')
  .controller('libraryController',
    ['$scope', '$translate', 'sessionState', '$timeout', '$state',
      function ($scope, $translate, sessionState, $timeout, $state) {
        $scope.canAccessContent = sessionState.canAccessContent
        $scope.isAdmin = sessionState.isAdmin
        $scope.showTabs = sessionState.canAccessContent && sessionState.canAccessGroupContent && $scope.isAdmin
        let loaderStopped = false
        $scope.currentRoute = ''
        $scope.activeTabIndex = 0 // default tab
        // params init
        $timeout(() => {
          $scope.show = true
        })

        $scope.tabs = [
          {
            title: '<i class="fa fa-play-circle"></i>&nbsp; ' + $translate.instant('MyContents'),
            route: 'myContents'
          },
          {
            title: '<i class="fa fa-users"></i>&nbsp; ' + $translate.instant('GroupContents'),
            route: 'groupContents'
          }
        ]

        $scope.go = route => {
          $scope.currentRoute = route
          //NB: must not reload parents or it will loop in the "go" call
          $state.go(route)
        }

        // ----
        // Select the correct tab on browser history navigation
        $scope.$on('library-my-contents-loaded', onLibraryStateEvent)
        $scope.$on('library-group-contents-loaded', onLibraryStateEvent)

        function onLibraryStateEvent(event, data) {
          $scope.tabs.forEach((tab, index) => {
            // find the tab index corresponding to the route name
            if (tab.route === data) {
              $scope.activeTabIndex = index
            }
          })
          event.stopPropagation()
        }
      }
    ])
