angular.module('mosaik.controllers')
  .controller('groupContentsController',
    ['$scope', '$window', '$translate', '$timeout', '$state', '$http', 'sessionState', 'contents', 'categories', 'contentSessionService', 'utilService', 'labelService', 'notificationHelper',
      function ($scope, $window, $translate, $timeout, $state, $http, sessionState, contents, categories, contentSessionService, utilService, labelService, notificationHelper) {
        $scope.canAccessContent = sessionState.canAccessContent
        $scope.isAdmin = sessionState.isAdmin
        $scope.canAccessGroupContents = sessionState.canAccessGroupContent && $scope.isAdmin

        var categoriesAsObjects = utilService.mapObjectsByKey(categories)
        $scope.contents = contents
        $scope.showFitlers = false
        $scope.showTodo = false
        $scope.showInProgress = false
        $scope.showFadeIn = false
        $scope.inProgressCount = 0
        $scope.todoCount = 0

        // isotope filter and search
        var currentFilter = '*'
        $scope.searchText = '' // binded to search input
        // isotope grid options
        var isotopeOptions = {
          masonry: {
            columnWidth: '.grid-sizer',
            fitWidth: true
          },
          initLayout: false,
          filter: function isotopeFilter() {
            // search with filter, stop if element does not contain filter class
            if (currentFilter !== '*' && !$(this).is(currentFilter)) {
              return false
            }
            // finally search from text if necessary
            if ($scope.searchText) {
              return $(this).find('.grid-item-title').text().match(new RegExp($scope.searchText, 'gi'))
            }
            return true
          }
        }
        var isotopeGrid // the grid instance

        // Start showing on next angular tick
        $timeout(function () {
          $scope.showFitlers = true
          $scope.showTodo = true
          $scope.showInProgress = true
        })

        $scope.$on('group-content-edit-closed', function (event, data) {
          // reload the contents
          contentSessionService.groupContents({ forceRefresh: true })
            .then(function (contents) {
              $scope.contents = contents
            })
        })

        scanContentsAndClassify(contents)

        function scanContentsAndClassify(contents) {
          var content, categoryids

          // loop on all contents to classify them in their categories
          for (var i = 0; i < contents.length; i++) {
            content = contents[i]
            content.classified = false

            // set the thumbnails sources
            content.thumbnails = {
              path: contentSessionService.formatContentPath(content),
              name: {
                md: 'thumbnail-md' + (content.areThumbnailsLocalized ? '-' + content.locale : ''),
                lg: 'thumbnail-lg' + (content.areThumbnailsLocalized ? '-' + content.locale : '')
              },
              src: {},
              srcset: {}
            }
            content.thumbnails.src.md = content.thumbnails.path + content.thumbnails.name.md + '.jpg'
            content.thumbnails.srcset.md = content.thumbnails.path + content.thumbnails.name.md + '@2x.jpg 2x, ' + content.thumbnails.path + content.thumbnails.name.md + '.jpg 1x'
            content.thumbnails.src.lg = content.thumbnails.path + content.thumbnails.name.lg + '.jpg'
            content.thumbnails.srcset.lg = content.thumbnails.path + content.thumbnails.name.lg + '@2x.jpg 2x, ' + content.thumbnails.path + content.thumbnails.name.lg + '.jpg 1x'

            // Set the category ids and name array
            var categoryids = content.categories ? content.categories.split(',') : []
            // sort the categories
            categoryids.sort(function (a, b) {
              return categoriesAsObjects[a].orderPriority - categoriesAsObjects[b].orderPriority
            })
            // counters
            incrementCounters(content)
            // filter classes
            setFilterClass(content)

            // loop on all content's categories
            content.categoryObjects = []
            for (var j = 0, categoryCount = categoryids.length; j < categoryCount; j++) {
              var categoryid = categoryids[j]
              var category = categoriesAsObjects[categoryid]
              // populate the category objects array, for the content-detail popup (cat. name as labels with optional color)
              content.categoryObjects.push({
                name: category.name,
                style: labelService.getLabelCustomStyleAsObj(category.color, category.isTextColorDefault)
              })
            }
          }
        }

        // Initialize the grid
        // wait for the repeat to be completed
        $scope.$on('repeat-done', function () {
          // initialize
          isotopeGrid = angular.element('.grid').isotope(isotopeOptions)
          // bind events
          isotopeGrid.one('arrangeComplete', function () {
            $scope.$broadcast('animate-grid-once')
          })

          // show the contents
          $timeout(function assignContentsList() {
            $scope.showFadeIn = true
            // then reload layout and sort on next tick
            $timeout(function () {
              // Make sure to load all items and relayout
              isotopeGrid.isotope('reloadItems')
              isotopeGrid.isotope()
            })
          })
        })

        function incrementCounters(content) {
          if (!content.startedAt) {
            return $scope.todoCount++
          }
          if (content.inProgress) {
            $scope.inProgressCount++
          }
        }

        function setFilterClass(content) {
          content.filterClass = ''
          if (content.inProgress) {
            content.filterClass += 'inprogress'
            $scope.showInProgress = true
            return
          }
          if (content.completed) {
            content.filterClass += ' completed'
            $scope.showCompleted = true
            return
          }
          content.filterClass += ' todo'
          $scope.showTodo = true
        }

        $scope.delete = function (content) {
          var sessionid = content.sessionid

          $window.Swal.fire({
            title: $translate.instant('PermanentDelete'),
            html: $translate.instant('GroupSessionDeleteText'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: $translate.instant('YesDeleteIt'),
            confirmButtonColor: '#d55',
            cancelButtonText: $translate.instant('NoKeepIt'),
            showLoaderOnConfirm: true,
            preConfirm: function () {
              return new Promise(function (resolve, reject) {
                $http.delete('/admin-api/session/' + sessionid)
                  .then(function success(response) {
                    resolve(response)
                  }, function error(response) {
                    resolve(response)
                  })
              })
            }
          }).then(function confirm(result) {
            if (!result.value) { // dismiss
              return
            }
            if (result.value.error || result.value.status !== 200) {
              return notificationHelper.swalError(result.value.error, result.value)
            }
            // remove deleted item from contents array, old school way
            const filteredContents = $scope.contents.filter(content => {
              return content.sessionid !== sessionid
            });
            $scope.$apply($scope.contents = filteredContents)
            isotopeGrid.isotope('layout')
          })
        }

        $scope.$on('locale-changed', function (event, args) {
          // reload only if locale is different from current contents list
          if (contents.length === 0 || args.locale !== contents[0].locale) {
            $state.go('groupContents', {}, { reload: true })
          }
        })

        $scope.$emit('library-group-contents-loaded', 'groupContents')

        $scope.applyCategoryFilter = function (filterClass) {
          currentFilter = filterClass
          // Clean the search box
          $scope.searchText = ''
          isotopeGrid.isotope()
        }

        $scope.applyTextFilter = utilService.debounce(250, function (event) {
          isotopeGrid.isotope()
        })
      }
    ])
