angular.module('mosaik.services')
  .factory('logoService',
    [
      '$translate', '$timeout',
      function ($translate, $timeout) {
        return {
          setLogo: function () {
            // load the top left logo with a delay, some config struggle to load the dictionnary
            $timeout(function () {
              var container = angular.element('#navbar-logo')
              var src = $translate.instant('MainLogoSrc')
              var srcset = $translate.instant('MainLogoSrcset')
              var paddingTop = $translate.instant('MainLogoPaddingTop')
              var width = $translate.instant('MainLogoWidth')
              var height = $translate.instant('MainLogoHeight')
              var img = '<img src="' + src + '" srcset="' + srcset + '" alt="logo" style="padding-top:' + paddingTop + '; width:' + width + '; height:' + height + ';"></img>'
              container.empty()
              container.append(img)
            }, 150)
          }
        }
      }
    ]
)
