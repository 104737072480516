angular.module('mosaik.controllers')
  .controller('myContentsController',
    ['$scope', '$state', '$stateParams', '$location', 'sessionState', 'myTags', 'contents', 'categories', '$translate', '$timeout', 'tutorialService', 'translationService', 'utilService', 'labelService', 'contentSessionService', 'notificationHelper',
      function ($scope, $state, $stateParams, $location, sessionState, myTags, contents, categories, $translate, $timeout, tutorialService, translationService, utilService, labelService, contentSessionService, notificationHelper) {
        $scope.canAccessContent = sessionState.canAccessContent
        $scope.showTitle = !(sessionState.canAccessContent && sessionState.canAccessGroupContent && sessionState.isAdmin)
        $scope.showFadeIn = false

        const categoriesAsObjects = utilService.mapObjectsByKey(categories)
        $scope.nonEmptyCategories = []
        $scope.contents = []
        $scope.renderedCount = 0
        $scope.showPriority = false
        $scope.showInProgress = false
        $scope.showToRedo = false
        $scope.showOthers = false
        $scope.inProgressIsVisibleInFilter = sessionState.inProgressCategory.isVisibleInFilter
        $scope.prioritiesIncompleteCount = 0
        $scope.toRedoCount = 0
        $scope.message = undefined

        //show messages
        $timeout(() => notificationHelper.notifyFromParams($stateParams))

        // isotope filter and search
        let currentFilterClass = '*'
        let currentFilter
        $scope.searchText = '' // binded to search input
        // isotope grid options
        const isotopeOptions = {
          masonry: {
            columnWidth: '.grid-sizer',
            fitWidth: true
          },
          initLayout: false,
          filter: function isotopeFilter() {
            // search with filter, stop if element does not contain filter class
            if (currentFilter !== '*' && !angular.element(this).is(currentFilter)) {
              return false
            }
            // finally search from text if necessary
            if ($scope.searchText) {
              return angular.element(this).find('.grid-item-title').text().match(new RegExp($scope.searchText, 'gi'))
            }
            return true
          }
        }
        let isotopeGrid // the grid instance      

        const groupContentsFilters = {} // a map for the group contents filter status
        const groupedContents = {} // a map for all the grouped contents; one array per group-contentid
        const inProgressesContents = []
        const prioritiesContents = []
        const toRedoContents = []
        const separatedCategoriesBeforeInProgress = []
        const separatedCategoriesAfterInProgress = []
        const othersContents = []
        let separatoridTracker = 0
        // convert tags array to map
        myTags = utilService.mapObjectsByKey(myTags, 'tagid')

        // Lazy image loader setup
        const lazyLoadObserver = lozad('.lozad', {
          load: element => {
            // Custom implementation to load an element
            element.src = element.getAttribute('data-img-src')
            element.srcset = element.getAttribute('data-img-srcset')
          }
        })

        performance.mark('content-controller-start')

        prepareCategoriesForClassification(categoriesAsObjects)
        scanContentsAndClassify(contents)
        reduceCategoriesToNonEmptyOnes(categoriesAsObjects)

        function scanContentsAndClassify(contents) {
          performance.mark('classify contents')
          let content, categoryids

          // loop on all contents to classify them in their categories
          for (let i = 0; i < contents.length; i++) {
            content = contents[i]
            content.classified = false

            // set the thumbnails sources
            setThumbnails(content)
            // Set the category ids and name array
            categoryids = setCategoryids(content)
            // Set the tag ids as an array
            setTagids(content)
            // Set the tag ids related to visibility restriction
            setTagidsVisibility(content)
            // Validate and set priority state
            setPriorityState(content)
            // Set the visibility state based on tag visibility
            setVisibilityState(content)
            // stop if not authorized by tag visibility or hidden
            if (!content.isVisible) {
              // Still show to admin but not if hidden
              if (!sessionState.isAdmin || content.isHidden === 1) continue
            }
            // redo state
            setRedoState(content)
            // counters
            incrementContentIncompleteCounters(content)
            incrementContentToRedoCounters(content)
            // Init content groups map if applicable
            setGroupContentsMaps(content)
            // set categories as object
            setCategoryObjects(content, categoryids)
            // filter classes
            setFilterClasses(content)
          }
        } // <-- end contents scanning loop ------

        function storeInArray(content, categoryArray) {
          categoryArray.push(content)
          content.classified = true
        }

        function prepareCategoriesForClassification(categoriesAsObjects) {
          performance.mark('prepare categories')
          // find categories that are separators, prepare them for contents classification
          for (let id in categoriesAsObjects) {
            categoriesAsObjects[id].contents = []
            categoriesAsObjects[id].count = 0
            categoriesAsObjects[id].incompleteCount = 0

            if (categoriesAsObjects[id].isSeparator === 1 && !categoriesAsObjects[id].showBeforeInProgress) {
              separatedCategoriesAfterInProgress.push(categoriesAsObjects[id])
            }
            if (categoriesAsObjects[id].showBeforeInProgress) {
              separatedCategoriesBeforeInProgress.push(categoriesAsObjects[id])
            }
            // set category name from locale
            const locale = translationService.getLocaleShort()
            for (const idloc in categoriesAsObjects[id].locales) {
              if (categoriesAsObjects[id].locales[idloc].locale === locale) {
                categoriesAsObjects[id].name = categoriesAsObjects[id].locales[idloc].name
                break
              }
            }
          }
        }

        function setThumbnails(content) {
          content.thumbnails = {
            path: contentSessionService.formatContentPath(content),
            name: {
              md: 'thumbnail-md' + (content.areThumbnailsLocalized ? '-' + content.locale : ''),
              lg: 'thumbnail-lg' + (content.areThumbnailsLocalized ? '-' + content.locale : '')
            },
            src: {},
            srcset: {}
          }
          content.thumbnails.src.md = content.thumbnails.path + content.thumbnails.name.md + '.jpg'
          content.thumbnails.srcset.md = content.thumbnails.path + content.thumbnails.name.md + '@2x.jpg 2x, ' + content.thumbnails.path + content.thumbnails.name.md + '.jpg 1x'
          content.thumbnails.src.lg = content.thumbnails.path + content.thumbnails.name.lg + '.jpg'
          content.thumbnails.srcset.lg = content.thumbnails.path + content.thumbnails.name.lg + '@2x.jpg 2x, ' + content.thumbnails.path + content.thumbnails.name.lg + '.jpg 1x'
        }

        function setCategoryids(content) {
          // remove unaccessible categories
          const categoryids = (content.categories ? content.categories.split(',') : []).filter(categoryid => categoriesAsObjects[categoryid] != null)
          // sort the categories
          categoryids.sort((a, b) => categoriesAsObjects[a].orderPriority - categoriesAsObjects[b].orderPriority)
          return content.categoryids = categoryids
        }

        function setTagids(content) {
          content.tagids = content.tags ? content.tags.split(',') : []
        }

        function setTagidsVisibility(content) {
          content.tagidsVisibility = content.tagsVisibility ? content.tagsVisibility.split(',') : []
          content.hasVisibilityRestriction = content.tagidsVisibility.length > 0
        }

        function setPriorityState(content) {
          // keep trace of original priority value
          if (!content.isPriorityOld) {
            content.isPriorityOld = content.isPriority
          }
          // A content with tagids is potentially a priority, if the user has one of the content's tags
          if (content.isPriorityOld && content.tagids.length > 0) {
            // validate if the priority status is still valid, i.e, the user has one of the tags associated with the content
            // Check if at least one the content's tags is associated with the user's tags
            const isPriorityByTag = content.tagids.some(tag => myTags[tag])
            // change dynamic isPriority value accordingly
            content.isPriority = isPriorityByTag ? 1 : 0
          }
        }

        function setVisibilityState(content) {
          // A content with tagidsVisibility is visible only to users with one of the tags
          // If the user has one of the tags associated with the content visibility, show the content, otherwise hide
          // Check if at least one the content's tags is associated with the user's tags
          // a content can also be hidden for this organisation
          const tagAuthorized = content.tagidsVisibility.length > 0 ? content.tagidsVisibility.some(tag => myTags[tag]) : true
          if (!tagAuthorized || content.isHidden === 1) {
            content.isVisible = false
          } else {
            content.isVisible = true
          }
        }

        function setRedoState(content) {
          content.toRedo = content.toRedoOnce || content.toRedoEveryInMonths ? 1 : 0
        }

        /** 
         * populate the categoryObjects array, for the content-detail popup (cat. name as labels with optional color)
         * content.categoryObjects array must exist
         * {int} categoryid
         * return {*} category object
         */
        function setContentCategoryObjFromCategoryid(content, categoryid) {
          const category = categoriesAsObjects[categoryid]
          content.categoryObjects.push({
            name: category.name,
            style: labelService.getLabelCustomStyleAsObj(category.color, category.isTextColorDefault)
          })
          return category
        }

        function incrementContentIncompleteCounters(content) {
          if (content.completionStatus !== 'completed' && content.isPriority) {
            $scope.prioritiesIncompleteCount++
          }
        }

        function incrementContentToRedoCounters(content) {
          if (content.toRedo) {
            $scope.toRedoCount++
          }
        }

        function incrementCategoryCounters(content, category) {
          category.count++
          if (content.completionStatus !== 'completed') {
            category.incompleteCount++
          }
        }

        function setGroupContentsMaps(content) {
          const groupid = content.isGroup ? content.id : content.isGroupedUnderContentid

          if (groupid) {
            if (!groupContentsFilters[groupid]) {
              // init the maps property for this group if not already created
              // map for the filters (in the scope):
              groupContentsFilters[groupid] = {
                expanded: false,
                filter: `:not(.group-${groupid})`,
              }
            }
            if (!groupedContents[groupid]) {
              // Temporary map for contents sorting 
              groupedContents[groupid] = []
            }
          }
        }

        function setFilterClasses(content) {
          const classes = []
          if (content.inProgress) {
            classes.push('inprogress')
            $scope.showInProgress = true
          }
          if (content.isPriority) {
            classes.push('priority')
            $scope.showPriority = true
          }
          if (content.toRedo) {
            classes.push('toredo')
            $scope.showToRedo = true
          }
          if (content.isGroup) {
            classes.push('is-group')
          }
          if (content.isGroupedUnderContentid) {
            classes.push(`group-${content.isGroupedUnderContentid}`)
          }
          if (content.isOther) {
            classes.push('other')
          }
          // set category classes
          content.categoryids.forEach(categoryid => {
            classes.push(`categoryid-${categoryid}`)
          })
          content.filterClass = classes.join(' ')
        }

        function setCategoryObjects(content, categoryids) {
          content.categoryObjects = []
          // loop on all content's categories
          for (let j = 0, categoryCount = categoryids.length; j < categoryCount; j++) {
            // populate the category objects array
            const category = setContentCategoryObjFromCategoryid(content, categoryids[j])
            // cat. counter
            incrementCategoryCounters(content, category)

            // content already classified, cannot be classified in more than one category or as a sub-item of a group content
            // (Because we cannot allow duplicate in the global isotope grid object)
            if (content.classified) {
              continue
            }

            // check if the content is grouped under another group-content
            if (content.isGroupedUnderContentid) {
              // Temporarely store the content in the groupContents[groupid].contents array
              storeInArray(content, groupedContents[content.isGroupedUnderContentid])
              continue
            }

            if (category.showBeforeInProgress) {
              // Content that shows up before "in progress"
              storeInArray(content, category.contents)
              continue
            }
            if (content.inProgress && sessionState.inProgressCategory.isSeparator) {
              // in progress
              storeInArray(content, inProgressesContents)
              continue
            }
            if (content.toRedo) {
              // to redo
              storeInArray(content, toRedoContents)
              continue
            }
            if (content.isPriority) {
              // priorities
              storeInArray(content, prioritiesContents)
              continue
            }
            if (category.isSeparator) {
              // contents after "in progress", "to redo" and "priority" and in categories that are separator
              storeInArray(content, category.contents)
              continue
            }
          } // <-- end for loop on content's catgories

          // "othersContents" contents (non priorities, not in separator)
          if (!content.classified) {
            content.isOther = 1
            storeInArray(content, othersContents)
          }
        }

        function reduceCategoriesToNonEmptyOnes() {
          performance.mark('reduce categories')
          for (let i = 0, len = categories.length; i < len; i++) {
            if (categories[i].count > 0) {
              $scope.nonEmptyCategories.push(categories[i])
            }
          }
        }

        function sortCategories(a, b) {
          return a.orderPriority - b.orderPriority || a.id - b.id
        }

        function sortContents(contents) {
          utilService.sortArrayByTwoProperties(contents, 'priority', sessionState.contentOrderingField)
        }

        function sortAndAddContentsToBuffer(contents) {
          let content
          sortContents(contents)
          for (let i = 0; i < contents.length; i++) {
            content = contents[i]
            // Special check for group content: set content status and classification based on sub-contents
            if (content.isGroup) {
              const subcontents = groupedContents[content.id]
              // prepare for insertion of the content and its subcontents
              content.completed = subcontents.length ? 1 : 0
              // add the top separator
              contentsBuffer.push({
                filterClass: 'grid-item-separator group-' + content.id,
                active: 1,
                iconClass: 'fa-object-group',
                iconStyle: {},
                titleText: utilService.stripHtmlTags(content.title),
                isSeparator: true,
                id: getNewSeparatorId(),
                showSeparator: true
              })
              // add the group content
              contentsBuffer.push(content)
              // on group content, add all grouped contents for this group following the group content
              sortContents(subcontents)
              // scan all content in that group-content to get the inprogress/completed status
              // and to add the sub-content to the main array
              for (let j = 0; j < subcontents.length; j++) {
                contentsBuffer.push(subcontents[j])
                // at least one is in progress
                if (subcontents[j].inProgress) {
                  content.inProgress = 1
                }
                // at least one is in priority
                if (subcontents[j].isPriority) {
                  content.isPriority = 1
                }
                // at least one is to redo
                if (subcontents[j].toRedo) {
                  content.toRedo = 1
                }
                // at least one is not completed
                if (!subcontents[j].completed) {
                  content.completed = 0
                }
              }
              // add the bottom separator
              contentsBuffer.push({
                filterClass: 'grid-item-separator group-' + content.id,
                active: 1,
                isSeparator: true,
                id: getNewSeparatorId(),
                isBottomSeparator: true,
                showSeparator: true
              })
              // reassign filter classes to the group content if inProgress and isPriority have changed
              setFilterClasses(content)
            } else {
              // Simply add the content
              contentsBuffer.push(content)
            }
          }
        }

        function getNewSeparatorId() {
          return separatoridTracker--
        }

        /**
         * 
         * @param {[Content]} contents 
         * @returns {[String]} Array of categoryid classes: category-1, etc.
         */
        function getContentCategoryClasses(contents) {
          return Array.from(new Set(contents.map(content => content.categoryids).flat())).map(id => `categoryid-${id}`)
        }


        /****************
         * Merge content arrays and give it to the grid for display
         */

        // merge contents arrays in the contents buffer array
        const contentsBuffer = []
        // add separated categories that show up before 'in progress' in same order received from server-side array
        // sort first:
        separatedCategoriesBeforeInProgress.sort(sortCategories)
        for (let i = 0, len = separatedCategoriesBeforeInProgress.length; i < len; i++) {
          const category = separatedCategoriesBeforeInProgress[i]
          const showSeparator = category.showSeparator === 1
          const stayVisibleWhenFiltered = category.stayVisibleWhenFiltered === 1
          const filterClasses = ['grid-item-separator']
          if (!showSeparator) {
            filterClasses.push('grid-item-empty-separator')
          }
          if (stayVisibleWhenFiltered) {
            getContentCategoryClasses(category.contents).forEach(categoryClass => {
              filterClasses.push(categoryClass)
            })

            if (category.alsoFilteredWithCategoryid) {
              filterClasses.push(`categoryid-${category.alsoFilteredWithCategoryid}`)
            }
          }

          // **** FIRST CATEGORIES ****
          // add the separator first
          if (category.contents.length && category.isSeparator) {
            contentsBuffer.push({
              filterClass: filterClasses.join(' '),
              active: 1,
              iconClass: category.iconClass || 'fa-info-circle',
              iconStyle: { color: category.color },
              titleText: category.name,
              isSeparator: true,
              id: getNewSeparatorId(),
              showSeparator
            })
          }
          // add the contents
          sortAndAddContentsToBuffer(category.contents)
        }

        // **** IN PROGRESS SECTION ****
        // add in progress contents
        if (inProgressesContents.length) {
          const showSeparator = sessionState.inProgressCategory.showSeparator
          const filterClasses = getContentCategoryClasses(inProgressesContents)
          if (inProgressesContents.some(content => content.isPriority === 1)) {
            filterClasses.push('priority')
          }
          if (inProgressesContents.some(content => content.toRedo === 1)) {
            filterClasses.push('toredo')
          }
          if (!showSeparator) {
            filterClasses.push('grid-item-empty-separator')
          }
          // add the separator first
          contentsBuffer.push({
            filterClass: `grid-item-separator inprogress ${filterClasses.join(' ')}`,
            active: 1,
            iconClass: 'fa-pause-circle',
            iconStyle: {},
            titleText: $translate.instant('InProgress'),
            isSeparator: true,
            id: getNewSeparatorId(),
            showSeparator,
            inProgress: true
          })
          // add the contents
          sortAndAddContentsToBuffer(inProgressesContents)
        }
        // **** TO REDO SECTION ****
        // add to redo contents
        if (toRedoContents.length) {
          const filterClasses = getContentCategoryClasses(toRedoContents)
          if (toRedoContents.some(content => content.isPriority === 1)) {
            filterClasses.push('priority')
          }
          // add the separator first
          contentsBuffer.push({
            filterClass: `grid-item-separator toredo ${filterClasses.join(' ')}`,
            active: 1,
            iconClass: 'fa-repeat',
            iconStyle: {},
            titleText: $translate.instant('ToRedo'),
            isSeparator: true,
            id: getNewSeparatorId(),
            showSeparator: true,
            isPriority: true
          })
          // add the contents
          sortAndAddContentsToBuffer(toRedoContents)
        }
        // **** PRIORITIES SECTION ****
        // add priorities
        if (prioritiesContents.length) {
          const filterClasses = getContentCategoryClasses(prioritiesContents)
          // add the separator first
          contentsBuffer.push({
            filterClass: `grid-item-separator priority ${filterClasses.join(' ')}`,
            active: 1,
            iconClass: 'fa-flag-checkered',
            iconStyle: {},
            titleText: $translate.instant('YourPriorities'),
            isSeparator: true,
            id: getNewSeparatorId(),
            showSeparator: true,
            isPriority: true
          })
          // add the contents
          sortAndAddContentsToBuffer(prioritiesContents)
        }
        // **** LAST CATEGORIES ****
        // add separated categories that show up "after" in progress contents in same order received from server-side array
        // sort first:
        separatedCategoriesAfterInProgress.sort(sortCategories)
        for (let i = 0, len = separatedCategoriesAfterInProgress.length; i < len; i++) {
          const category = separatedCategoriesAfterInProgress[i]
          const showSeparator = category.showSeparator === 1
          const stayVisibleWhenFiltered = category.stayVisibleWhenFiltered === 1
          const filterClasses = ['grid-item-separator']
          if (!showSeparator) {
            filterClasses.push('grid-item-empty-separator')
          }
          if (stayVisibleWhenFiltered) {
            getContentCategoryClasses(category.contents).forEach(categoryClass => {
              filterClasses.push(categoryClass)
            })

            if (category.alsoFilteredWithCategoryid) {
              filterClasses.push(`categoryid-${category.alsoFilteredWithCategoryid}`)
            }
          }

          // add the separator first
          if (category.contents.length && category.isSeparator) {
            contentsBuffer.push({
              filterClass: filterClasses.join(' '),
              active: 1,
              iconClass: category.iconClass || 'fa-flag',
              iconStyle: { color: category.color },
              titleText: category.name,
              isSeparator: true,
              id: getNewSeparatorId(),
              showSeparator
            })
          }
          // add the contents
          sortAndAddContentsToBuffer(category.contents)
        }

        // **** Add Others "section" ****
        // add the "others" separator if applicable
        if (othersContents.length) {
          $scope.showOthers = true
          contentsBuffer.push({
            filterClass: 'grid-item-separator other',
            active: 1,
            iconClass: 'fa-flag-o',
            iconStyle: {},
            titleText: $translate.instant('Others'),
            isSeparator: true,
            showSeparator: true
          })
        }
        // add the contents
        sortAndAddContentsToBuffer(othersContents)

        // calculate grid default filter
        currentFilter = getCurrentGridFilters(currentFilterClass)

        // assign contents
        $scope.contents = contentsBuffer

        performance.mark('merge arrays')

        // Initialize the grid
        // wait for the repeat to be completed
        $scope.$on('repeat-done', () => {
          performance.mark('repeat-done')

          // initialize
          isotopeGrid = angular.element('.grid').isotope(isotopeOptions)
          // bind events
          isotopeGrid.one('arrangeComplete', () => {
            $scope.$emit('stop-loader')

            $scope.$broadcast('animate-grid-once')
            // lazy load images
            lazyLoadObserver.observe()
            // performance report complete
            performance.mark('arrangeComplete')
            logPerformance()
            // apply priority auto filter (if set for the user)
            if ($scope.showPriority && sessionState.priorityAutoFilter) {
              angular.element('#priority-filter').click()
              notificationHelper.info($translate.instant('PriorityAutoFilterApplied'), { duration: 5000, keepPrevious: true })
            }
          })

          // show tutorial and show the contents
          $timeout(() => {
            tutorialService.showIfFirstConnect(sessionState, () => {
              // show the grid
              $scope.showFadeIn = true
              // make sure the loader is removed without fade
              angular.element('loader-square').removeClass('animate-show-fade')
              // then reload layout and sort on next tick
              $timeout(() => {
                // Make sure to load all items and relayout
                isotopeGrid.isotope('reloadItems')
                isotopeGrid.isotope()
                // check for content param
                autoPopupContent()
              })
            })
          })
        })

        $scope.$on('state-success-myContents', () => $timeout(() => autoPopupContent()))

        // get the contentid from one of the authorized state params
        const getContentidParam = () => {
          const cid = $stateParams.cid || $stateParams.contentid || $location.search().contentid
          return parseInt(cid)
        }
        /**
         * Check if there is a contentid or activityid parameter and show it's popup
         */
        function autoPopupContent() {
          const contentid = getContentidParam()
          const activityid = $location.search().activityid
          let foundContent

          if (!isNaN(contentid)) foundContent = $scope.contents.find(content => content.id === contentid)
          if (!foundContent && activityid) foundContent = $scope.contents.find(content => content.activityid === activityid)

          if (foundContent) {
            // click with a delay to let time for the DOM to finish. (not sure it's necessary)
            $timeout(() => angular.element('#content-' + foundContent.id).click(), 350)
          }
        }

        $scope.$on('content-detail-closing', (event, content) => {
          // clear the url if content refs are in it
          $timeout(() => {
            const contentid = getContentidParam()
            const activityid = $location.search().activityid
            // only clear if content refs are the same as the current content popup
            if (content.id === contentid || content.activityid === activityid) {
              $location.search('contentid', null)
              $location.search('activityid', null)
              $location.replace()
            }
          })
        })

        $scope.$on('locale-changed', (event, args) => {
          // reload only if locale is different from current contents list
          if (contents.length === 0 || args.locale !== contents[0].locale) {
            $state.go('myContents', {}, { reload: true })
          }
        })

        $scope.$emit('library-my-contents-loaded', 'myContents')

        function getCurrentGridFilters(filterClass) {
          // format the isotope search filter
          let completeFilter = ''
          const filters = filterClass.split(',')
          for (let i = 0; i < filters.length; i++) {
            let filterClass = filters[i]
            // add a "or" separator if there was a previons filter
            if (completeFilter !== '') {
              completeFilter += ','
            }
            for (const groupContentid in groupContentsFilters) {
              if (!groupContentsFilters[groupContentid].expanded) {
                filterClass += groupContentsFilters[groupContentid].filter
              }
            }
            completeFilter += filterClass
          }
          return completeFilter
        }

        $scope.applyCategoryFilter = filterClass => {
          currentFilterClass = filterClass
          currentFilter = getCurrentGridFilters(filterClass)
          // Clean the search box
          $scope.searchText = ''
          isotopeGrid.isotope()
        }

        $scope.applyTextFilter = utilService.debounce(250, (event) => isotopeGrid.isotope())

        $scope.applyGroupFilter = groupContentid => {
          const groupClass = '.group-' + groupContentid
          // toggle the group
          groupContentsFilters[groupContentid].expanded = !groupContentsFilters[groupContentid].expanded
          // reapply filters
          currentFilter = getCurrentGridFilters(currentFilterClass)
          // check if subcontents is already loaded
          if (groupedContents[groupContentid].length) {
            // relayout the Grid and recheck lazy loader
            isotopeGrid.isotope()
            return isotopeGrid.one('arrangeComplete', () => $timeout(() => {
              lazyLoadObserver.observe()
            }))
          }

          // load the subcontents
          contentSessionService.subContents({ groupContentid: groupContentid, forceRefresh: false }).then(subcontents => {
            // ---Still in dev
            let content, categoryids

            // loop on all contents to classify them in their categories
            for (let i = 0; i < subcontents.length; i++) {
              content = subcontents[i]

              if (content.classified) {
                storeInArray(content, groupedContents[groupContentid])
                continue
              }

              // set the thumbnails sources
              setThumbnails(content)
              // Set the category ids and name array
              categoryids = setCategoryids(content)
              // Set the tag ids as an array
              setTagids(content)
              // Set the tag ids related to vi
              setTagidsVisibility(content)
              // Validate and set priority state
              setPriorityState(content)
              // Set the visibility state based on tag visibility
              setVisibilityState(content)
              // stop if not authorized by tag visibility or hidden
              if (!content.isVisible) {
                // Still show to admin but not if hidden
                if (!sessionState.isAdmin || content.isHidden === 1) continue
              }
              // redo state
              setRedoState(content)
              // counters
              incrementContentIncompleteCounters(content)
              incrementContentToRedoCounters(content)
              // Init content groups map if applicable
              setGroupContentsMaps(content)
              // set categories as object
              setCategoryObjectsForSubContent(content, categoryids)
              // filter classes
              setFilterClasses(content)
            }

            // --- insert into the grid
            // find group position
            const groupeIndex = $scope.contents.findIndex((content) => content.id === groupContentid)
            sortContents(groupedContents[groupContentid])

            $timeout(() => {
              // Insert the new contents in the contents array used by the grid 
              $scope.contents.splice(groupeIndex + 1, 0, ...groupedContents[groupContentid])
              // Open the group item with it's separators (if any)
              isotopeGrid.isotope()
              // Wait for Angular to sync the contents
              $timeout(() => {
                // Load the new items
                isotopeGrid.isotope('reloadItems')
                isotopeGrid.one('arrangeComplete', () => {
                  $timeout(() => {
                    lazyLoadObserver.observe()
                    $scope.$broadcast('animate-grid-refresh-level2')
                  })
                })
                // Show the newly added items by re-arranging the grid
                isotopeGrid.isotope()
              })
            })
          })


          function setCategoryObjectsForSubContent(subContent, categoryids) {
            subContent.categoryObjects = []
            // loop on all content's categories
            for (let j = 0, categoryCount = categoryids.length; j < categoryCount; j++) {
              // populate the category objects array
              let category = setContentCategoryObjFromCategoryid(subContent, categoryids[j])
              // cat. counter
              incrementCategoryCounters(subContent, category)
              // content already classified, cannot be classified in more than one category or as a sub-item of a group content
              // (Because we cannot allow duplicate in the global isotope grid object)
              if (subContent.classified) {
                continue
              }
              // Temporarely store the content in the groupContents[groupid].contents array
              storeInArray(subContent, groupedContents[groupContentid])
              continue
            }
          }
        }

        /**
         * Performance measures report in console
         */
        // log perf:
        let initialPerfLogged = false

        function logPerformance() {
          // only log once
          if (initialPerfLogged) return
          initialPerfLogged = true

          performance.measure('From "mycontents-resolve-start" to "content-controller-start"', 'mycontents-resolve-start', 'content-controller-start')
          performance.measure('From "content-controller-start" to "prepare categories"', 'content-controller-start', 'prepare categories')
          performance.measure('From "prepare categories" to "classify contents"', 'prepare categories', 'classify contents')
          performance.measure('From "classify contents" to "reduce categories"', 'classify contents', 'reduce categories')
          performance.measure('From "reduce categories" to "merge arrays"', 'reduce categories', 'merge arrays')
          performance.measure('From "merge arrays" to "grid repeat-done"', 'merge arrays', 'repeat-done')
          performance.measure('From "grid repeat-done" to "grid arrangeComplete"', 'repeat-done', 'arrangeComplete')
          console.log(performance.getEntriesByType("measure"))
          performance.clearMarks()
          performance.clearMeasures()
        }
      }
    ])
