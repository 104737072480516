angular.module('mosaik.directives')
  .directive('repeatContentRefs',
    ['locationHelper', 'contentSessionService',
      function (locationHelper, contentSessionService) {
        return {
          restrict: 'A',
          scope: {
            ref: '=',
            content: '='
          },
          link: (scope, element, attrs) => {
            var href = ''
            // set reference's url
            if (typeof scope.ref.href === 'string') {
              href = scope.ref.href
            } else {
              href = scope.ref.href[scope.content.locale] || scope.ref.href[scope.content.defaultLocale]
            }

            if (href.indexOf('http') === 0) {
              scope.ref.url = href
            } else {
              scope.ref.url = contentSessionService.formatContentPath(scope.content) + href
            }
            // set reference's name
            if (typeof scope.ref.name === 'string') {
              scope.ref.localizedName = scope.ref.name
            } else {
              scope.ref.localizedName = scope.ref.name[scope.content.locale] || scope.ref.name[scope.content.defaultLocale]
            }
          }
        }
      }])

  .directive('clickShowContentDetail',
    ['$window', '$http', '$sce', '$templateRequest', '$compile', 'sessionStateService', 'contentSessionService', '$location', '$timeout', 'certificateService',
      function ($window, $http, $sce, $templateRequest, $compile, sessionStateService, contentSessionService, $location, $timeout, certificateService) {
        return {
          restrict: 'A',
          scope: {
            content: '='
          },
          link: (scope, element, attrs) => {
            element.on('click', () => {
              if (scope.vexInstance && scope.vexInstance.isOpen) {
                return // don't show twice
              }

              $templateRequest('/public/views/library/contentDetail.html').then(template => {
                // check the session is still valid
                sessionStateService.validSessionOrRedirect()
                  .then(sessionState => {
                    if (!sessionState || !sessionState.connected) {
                      return
                    }
                    let contentData
                    scope.isAdmin = sessionState.isAdmin

                    $http.get(contentSessionService.formatContentPath(scope.content) + 'config.json/' + scope.content.id)
                      .then(response => {
                        const defaultLocale = response.data.defaultLocale
                        const contentLocale = scope.content.locale

                        contentData = {
                          description: getTrustedStringFromProperty(response.data, 'description', contentLocale, defaultLocale),
                          configSubtitle: getTrustedStringFromProperty(response.data, 'subtitle', contentLocale, defaultLocale),
                          evaluationCriteria: getTrustedStringFromProperty(response.data, 'evaluationCriteria', contentLocale, defaultLocale),
                          showDuration: response.data.durationInMinutes != null,
                          durationInMinutes: response.data.durationInMinutes,
                          references: response.data.references,
                          entryPoint: response.data.entryPoint,
                          target: response.data.target,
                          locale: contentLocale,
                          defaultLocale,
                          startable: response.data.startable,
                          external: response.data.external
                        }
                      }, error => {
                        contentData = {
                          description: 'The description is <b>empty</b>.',
                          configSubtitle: 'No subtitle',
                          evaluationCriteria: 'No evaluation criteria',
                          durationInMinutes: '15',
                          references: [],
                          entryPoint: undefined,
                          target: undefined,
                          locale: 'en',
                          defaultLocale: 'en',
                          startable: false,
                          external: false
                        }
                      })
                      .finally(() => {
                        angular.extend(scope.content, contentData)
                        showContentVexContainer($compile(template)(scope), scope.content)
                      })
                  })
              })
            })

            function getTrustedStringFromProperty(data, propertyName, contentLocale, defaultLocale) {
              let value
              if (!data[propertyName]) {
                return ''
              }
              if (data[propertyName][contentLocale] != null) {
                value = data[propertyName][contentLocale]
              } else if (data[propertyName][defaultLocale] != null) {
                value = data[propertyName][defaultLocale]
              } else {
                value = data[propertyName] || ''
              }

              if (typeof value !== 'string') {
                return ''
              }
              return $sce.trustAsHtml(value)
            }

            function showContentVexContainer(compiledContent, content) {
              $window.vex.open({
                contentClassName: 'vex-mosaik-content',
                afterOpen: function () {
                  scope.vexInstance = this
                  angular.element(this.contentEl).append(compiledContent)
                },
                beforeClose: () => scope.$emit('content-detail-closing', content)
              })
            }

            function closeModal() {
              if (scope.vexInstance) $window.vex.closeAll()
            }

            scope.$on('close-content-popup', closeModal)

            scope.getCertificate = sessionid => {
              closeModal()
              certificateService.get('me', sessionid)
            }
          }
        }
      }])

  .directive('clickStartContent',
    ['$window', '$rootScope', '$http', '$location', '$translate', 'sessionStateService', 'notificationHelper',
      function ($window, $rootScope, $http, $location, $translate, sessionStateService, notificationHelper) {
        return {
          restrict: 'A',
          scope: {
            content: "=",
            consult: "@"
          },
          link: (scope, element, attrs) => {
            element.on('click', () => {
              // check for valid session first
              sessionStateService.validSessionOrRedirect()
                .then(sessionState => {
                  if (!sessionState || !sessionState.connected) {
                    return
                  }
                  const restart = scope.content.completionStatus === 'completed' && !scope.content.toRedo
                  const consult = scope.consult === 'true'
                  const data = {
                    contentid: scope.content.id,
                    sessionid: scope.content.sessionid ? scope.content.sessionid : '',
                    consult,
                    redirectState: $location.$$path
                  }
                  

                  // if consulting, ask before launching if it's ok to consult.
                  if (consult) {
                    $window.Swal.fire({
                      icon: 'warning',
                      title: $translate.instant('ConsultWarn'),
                      html: $translate.instant('ConsultWarnText'),
                      showCancelButton: true,
                      showCloseButton: true,
                      confirmButtonText: $translate.instant('ConsultWarnConfirm'),
                      cancelButtonText: $translate.instant('ConsultWarnCancel'),
                      customClass: {
                        popup: 'swal2-popup-large'
                      },
                    }).then(result => beforeLaunch(result, 'isConfirmed'))
                  } else {
                    if (restart) {
                      $window.Swal.fire({
                        icon: 'info',
                        title: $translate.instant('RestartInfo'),
                        html: $translate.instant('RestartInfoText'),
                        showCancelButton: true,
                        showCloseButton: true,
                        confirmButtonText: $translate.instant('RestartInfoConfirm'),
                        cancelButtonText: $translate.instant('RestartInfoCancel'),
                        customClass: {
                          popup: 'swal2-popup-large'
                        },
                      }).then(result => beforeLaunch(result, 'isDismissed'))
                    } else {
                      launch()
                    }
                  }

                  function beforeLaunch(promptResult, consultProperty) {
                    if (promptResult.isDismissed && promptResult.dismiss !== 'cancel') return // prompt cancelled
                    data.consult = promptResult[consultProperty]
                    launch()
                  }

                  function launch() {
                    $http.put('/player/setContent', data)
                      .then(success => {
                        // The content has been setup, open the player
                        // an external content plays outside the player with no tracking
                        const url = scope.content.external ? scope.content.entryPoint : '/player'

                        // The content may require to open in a new window/tab
                        if (scope.content.target === '_blank') {
                          $rootScope.$broadcast('close-content-popup')
                          return $window.open(url)
                        }
                        $window.location = url
                      })
                      .catch(error => notificationHelper.failureFrom(error))
                  }
                })
            })
          }
        }
      }])

  .directive('shakeCertificate',
    ['animateService',
      function (animateService) {
        return {
          restrict: 'A',
          link: (scope, element, attrs) => {
            element.on('click', () => {
              animateService.animateCss('.modal-content-document-certificat-icon', 'animate__flash', () => animateService.animateCss('.modal-content-btn-start', 'animate__pulse'))
            })
          }
        }
      }])
