angular.module('mosaik.controllers')
  .controller('dashboardUserController',
    ['$scope', '$state', '$stateParams', 'sessionState', 'groups', 'roles', 'tags', 'organizations', 'tagService', '$window', '$translate', '$timeout', 'datatablesHelper', 'notificationHelper', '$http', 'utilService', '$interpolate', 'translationService', 'formService', 'passwordService', 'userRoleService',
      function ($scope, $state, $stateParams, sessionState, groups, roles, tags, organizations, tagService, $window, $translate, $timeout, datatablesHelper, notificationHelper, $http, utilService, $interpolate, translationService, formService, passwordService, userRoleService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-user'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/user',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector,
          loadRoles: 1
        }
        $scope.queryDefault = {
          state: 'active',
          tagFilterMethod: 'or'
        }
        $scope.query = {
          state: '',
          tagFilterMethod: '',
          groups: [],
          tags: [],
          roles: [],
          organizations: [],
          search: ''
        }
        // data
        $scope.groups = groups
        const groupsAsObjects = utilService.mapObjectsByKey(groups)
        $scope.tags = tags
        $scope.roles = roles
        $scope.organizations = organizations
        const organizationsAsObjects = utilService.mapObjectsByKey(organizations)
        // editors
        let editorAdd
        let editorActive
        let editorDelete
        let editorRole
        let editorLocale
        let editorAddTags
        let editorRemoveTags
        let editorChangeEmail
        let editorChangeDisplayName
        let editorChangeInternalUserid
        let editorPriorityAutoFilter
        // DT table
        $scope.table

        const emailIsValid = email => utilService.getEmailRegex().test(email)

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', initializeTable))

        function initializeTable() {
          editorAdd = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              label: '<i class="fa fa-user fa-fw"></i>&nbsp;' + $translate.instant('Name'),
              name: 'displayName',
              attr: {
                required: true,
                name: 'displayName'
              }
            }, {
              label: $translate.instant('Email'),
              name: 'email',
              attr: {
                required: true,
                pattern: utilService.getEmailPattern(),
                autocomplete: "new-email",
                autocapitalize: "new-email",
                name: 'email',
                type: 'email'
              }
            }, {
              name: 'pwDivider',
              type: 'divider'
            }, {
              label: $translate.instant('Password'),
              name: 'password',
              type: 'passwordToggle',
              attr: {
                minlength: passwordService.getMinLength(),
                required: true,
                pattern: utilService.getPasswordPattern(),
                autocomplete: "new-password",
                name: 'password'
              }
            }, {
              label: '',
              name: 'passwordIsTemporary',
              type: 'checkboxFlex',
              options: datatablesHelper.getPasswordIsTemporaryOptions(),
              unselectedValue: 0,
              separator: ''
            }, {
              name: 'useridDivider',
              type: 'divider'
            }, {
              label: datatablesHelper.formatOptionalField('InternalUserid'),
              name: 'internalUserid',
              attr: {
                pattern: utilService.getInternalUseridPattern(),
                autocomplete: "new-internal-userid",
                name: 'internalUserid'
              }
            }, {
              name: 'roleTagsDivider',
              type: 'divider'
            }, {
              label: '<i class="fa fa-id-badge fa-fw"></i>&nbsp;' + $translate.instant('Role'),
              name: 'roleid',
              type: 'selectPicker',
              attr: {
                required: true,
                'data-live-search': 'false'
              }
            }, {
              label: '<i class="fa fa-industry fa-fw"></i>&nbsp;' + $translate.instant('OrgName'),
              name: 'orgid',
              type: 'selectPicker',
              options: organizations,
              optionsPair: {
                label: 'name',
                value: 'id'
              },
              renderLabel: datatablesHelper.renderLabelOrg
            }, {
              name: 'orgDivider',
              type: 'divider'
            }, {
              label: $translate.instant('Tags'),
              name: 'tags[].tagid',
              type: 'selectPicker',
              className: 'with-label',
              multiple: true,
              attr: {
                'data-multiple-separator': ' '
              },
              optionsPair: {
                label: 'name',
                value: 'id'
              },
              renderLabel: tagService.renderLabel
            }, {
              name: 'priorityDivider',
              type: 'divider'
            }, {
              label: $translate.instant('PriorityAutoFilter'),
              name: 'priorityAutoFilter',
              type: 'radio',
              options: datatablesHelper.getYesNoOptionsLabels('PriorityAutoFilterOnHint', 'PriorityAutoFilterOffHint'),
              default: 0
            }, {
              name: 'xDivider',
              type: 'divider'
            }, {
              label: '',
              name: 'createAnother',
              type: 'checkboxFlex',
              options: [{ label: $translate.instant('CreateAnotherUser'), value: true }],
              unselectedValue: false,
              separator: ''
            }],
            ajax: {
              create: {
                type: 'PUT',
                url: '/admin-api/user/create'
              }
            }
          }))

          // set dependence on orgid: the tags list depends on the selected orgid
          datatablesHelper.setDependentAndPopulateChild(editorAdd, 'orgid', organizationsAsObjects, 'tags', 'tags[].tagid', tagService.tags)

          const clearCacheUsers = () => $scope.$emit('data-modified', ['user', 'coverage'])

          editorAdd.on('preSubmit', (e, data, action) => {
            const row = data.data[0]
            editorAdd.createAnother = row.createAnother
            editorAdd.passwordIsTemporary = row.passwordIsTemporary
            editorAdd.showPassword = editorAdd.field('password').visible()
            editorAdd.tags = row.tags
            editorAdd.priorityAutoFilter = row.priorityAutoFilter

            return datatablesHelper.onPreSubmit(action, editorAdd)
          })
          editorAdd.on('submitSuccess', (e, json, data, action) => {
            if (editorAdd.createAnother) {
              $timeout(() => add(), 700)
            }
          })
          // After user add, clear users in cache
          editorAdd.on('postSubmit', clearCacheUsers)

          editorActive = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: '<i class="fa fa-toggle-on fa-fw"></i>&nbsp;' + $translate.instant('State'),
              name: 'active',
              type: 'radio',
              options: datatablesHelper.getActiveInactiveOptionsLabels('ActiveUserHint', 'InactiveUserHint'),
              default: 1
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/changeActiveState'
              }
            }
          }))
          // After user state change, clear users in cache
          editorActive.on('postSubmit', clearCacheUsers)


          editorRole = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: '<i class="fa fa-id-badge fa-fw"></i>&nbsp;' + $translate.instant('Role'),
              name: 'roleid',
              type: 'selectPicker',
              attr: {
                required: true,
                'data-live-search': 'false'
              }
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/changeRole'
              }
            }
          }))


          editorDelete = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: '',
              name: 'deactivateLockedUser',
              type: 'checkboxFlex',
              options: [{ label: $translate.instant('OverridesMasterOrgPreferredName'), value: 1 }],
              unselectedValue: 0,
              separator: '',
              default: 0
            }],
            ajax: {
              remove: {
                type: 'DELETE',
                url: '/admin-api/user/_id_',
                deleteBody: false
              }
            }
          }))
          // After delete user, clear users in cache
          editorDelete.on('postSubmit', clearCacheUsers)


          editorPriorityAutoFilter = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: '<i class="fa fa-filter-on fa-fw"></i>&nbsp;' + $translate.instant('PriorityAutoFilter'),
              name: 'priorityAutoFilter',
              type: 'radio',
              options: datatablesHelper.getYesNoOptionsLabels('PriorityAutoFilterOnHint', 'PriorityAutoFilterOffHint'),
              default: 1
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/changePriorityAutoFilter'
              }
            }
          }))

          editorLocale = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('Locale'),
              name: 'locale',
              type: 'selectPicker',
              options: translationService.getLanguagesAsDatatableOptions(),
              default: translationService.getLocaleShort(),
              attr: {
                required: true,
                'data-live-search': 'false'
              }
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/changeLocale'
              }
            }
          }))

          editorAddTags = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('CheckTagsToAdd'),
              name: 'tags[].tagid',
              type: 'checkbox'
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/addTags'
              }
            }
          }))

          editorRemoveTags = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('CheckTagsToRemove'),
              name: 'tags[].tagid',
              type: 'checkbox'
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/removeTags'
              }
            }
          }))

          // After tag edit, can be necessary to clear tags in cache
          const clearCacheTags = (e, json, data, action) => $scope.$emit('data-modified', ['tag', 'coverage'])
          editorAddTags.on('postSubmit', clearCacheTags)
          editorRemoveTags.on('postSubmit', clearCacheTags)

          editorChangeEmail = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('NewEmail'),
              name: 'email',
              attr: { required: true }
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/changeEmail/_id_'
              }
            }
          }))

          editorChangeDisplayName = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: $translate.instant('DisplayName'),
              name: 'displayName',
              attr: { minlength: 2, maxLength: 254, required: true }
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/changeDisplayName/_id_'
              }
            }
          }))

          editorChangeInternalUserid = datatablesHelper.createEditor(angular.extend(datatablesHelper.getEditorParams({ closeOnBackground: false }), {
            fields: [{
              type: 'hidden',
              name: 'id'
            }, {
              type: 'hidden',
              name: 'orgid'
            }, {
              label: datatablesHelper.formatOptionalField('InternalUserid'),
              name: 'internalUserid',
              attr: { minlength: 0, maxLength: 64 }
            }],
            ajax: {
              edit: {
                type: 'PUT',
                url: '/admin-api/user/changeInternalUserid/_id_'
              }
            }
          }))

          const hiddenButtons = ['create', 'edit', 'remove']
          const extraButtons = [
            {
              extend: 'create',
              editor: editorAdd,
              text: $translate.instant('AddUser'),
              action: (e, dt, node, config) => add()
            }, {
              extend: 'collection',
              text: '<i class="fa fa-tags fa-fw"></i>&nbsp;' + $translate.instant('ChangeTags'),
              className: 'dropdown-toggle dropup',
              dropup: true,
              fade: 100,
              autoClose: true,
              buttons: [{
                extend: 'edit',
                editor: editorAddTags,
                text: tagService.tagPlusIcons() + $translate.instant('EditUserAddTags'),
                action: (e, dt, node, config) => addTags(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                editor: editorRemoveTags,
                text: tagService.tagMinusIcons() + $translate.instant('EditUserRemoveTags'),
                action: (e, dt, node, config) => removeTags(dt.rows({ selected: true }))
              }]
            },
            {
              extend: 'collection',
              text: `<span>${$translate.instant('OtherActions')}`,
              className: 'dropdown-toggle dropup',
              dropup: true,
              fade: 100,
              autoClose: true,
              buttons: [{
                extend: 'edit',
                editor: editorActive,
                text: $translate.instant('EditUserState'),
                className: 'change-state',
                action: (e, dt) => changeState(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                editor: editorRole,
                text: $translate.instant('EditUserRole'),
                className: 'change-role',
                action: (e, dt) => changeRole(dt.rows({ selected: true }))
              }, {
                className: 'divider'
              }, {
                extend: 'edit',
                editor: editorLocale,
                text: $translate.instant('EditUserLocale'),
                className: 'change-locale',
                action: (e, dt) => changeLocale(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                editor: editorChangeDisplayName,
                text: $translate.instant('EditUserChangeDisplayName'),
                className: 'change-display-name',
                action: (e, dt) => changeDisplayName(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                editor: editorChangeEmail,
                text: $translate.instant('EditUserChangeEmail'),
                className: 'change-email',
                action: (e, dt) => changeEmail(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                editor: editorChangeInternalUserid,
                text: $translate.instant('EditUserChangeInternalUserid'),
                className: 'change-internal-userid',
                action: (e, dt) => changeInternalUserid(dt.rows({ selected: true }))
              }, {
                className: 'divider'
              }, {
                extend: 'edit',
                text: $translate.instant('EditUserChangePassword'),
                className: 'change-password',
                action: (e, dt) => changePassword(dt.rows({ selected: true }))
              }, {
                className: 'divider'
              }, {
                extend: 'edit',
                editor: editorPriorityAutoFilter,
                text: $translate.instant('EditUserPriorityAutoFilter'),
                className: 'change-priority-auto-filter',
                action: (e, dt) => changePriorityAutoFilter(dt.rows({ selected: true }))
              }, {
                className: 'divider'
              }, {
                extend: 'edit',
                text: $translate.instant('ShowInvitation'),
                action: (e, dt) => showInvitations(dt.rows({ selected: true }))
              }, {
                className: 'divider'
              }, {
                extend: 'edit',
                text: $translate.instant('Delete'),
                action: (e, dt) => deleteUser(dt.rows({ selected: true }))
              }]
            },
            {
              extend: 'collection',
              text: `<span>${$translate.instant('UsageReports')}`,
              className: 'dropdown-toggle dropup',
              dropup: true,
              fade: 100,
              autoClose: true,
              buttons: [{
                extend: 'edit',
                text: $translate.instant('ShowCompletedSessions'),
                className: 'usage-report',
                action: (e, dt) => showCompletedSessions(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                text: $translate.instant('ShowInProgressSessions'),
                className: 'usage-report',
                action: (e, dt) => showInProgressSessions(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                text: $translate.instant('ShowTimeUsage'),
                className: 'usage-report',
                action: (e, dt) => showTimeUsage(dt.rows({ selected: true }))
              }, {
                extend: 'edit',
                text: $translate.instant('ShowPriorityCoverage'),
                className: 'usage-report-priority-coverage',
                action: (e, dt) => showPriorityCoverage(dt.rows({ selected: true }))
              }]
            }
          ]
          const exportOptions = {
            columns: sessionState.isRoot ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] : [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
          }
          const exportMessage = $translate.instant('Users')

          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            hiddenButtons,
            extraButtons,
            exportOptions,
            exportMessage,
            extraDTOptions: {
              pageLength: 10, // force default page length to reset to smallest possible
              serverSide: true
            },
            serverSideExport: false,
            excelForceStrCols: sessionState.isRoot ? ['E'] : ['C']
          }), {
            columns: [
              {
                data: 'lastAccessAt',
                orderData: 1,
                render: (data, type, row) => data ? $window.moment(data).fromNow() : ''
              },
              {
                data: 'lastAccessAt',
                visible: false,
                className: 'never',
                searchable: false,
                render: (data, type, row) => data ? $window.moment(data).format('YYYY/MM/DD HH:mm') : ''
              },
              { // orgname and internalOrgid
                data: 'orgName',
                visible: sessionState.isRoot,
                className: (!sessionState.isRoot ? 'never' : ''),
                render: (data, type, row) => {
                  // only used by root
                  if (!sessionState.isRoot) {
                    return ''
                  }
                  let orgid = row.orgid
                  let org = orgid && organizationsAsObjects[orgid] ? organizationsAsObjects[orgid] : {}
                  return datatablesHelper.renderOrgName(orgid, row.internalOrgid, data, org.active)
                }
              },
              { // org groups
                data: 'groups',
                searchable: false,
                orderable: false,
                responsivePriority: 10001,
                visible: sessionState.isRoot,
                className: (!sessionState.isRoot ? 'never' : ''),
                render: datatablesHelper.renderGroups
              },
              {
                data: 'id',
                visible: false
              },
              {
                data: 'internalUserid',
                className: 'none'
              },
              {
                data: 'displayName',
                render: datatablesHelper.sanitizeEscape
              },
              {
                data: 'email',
                render: (data, type, row) => datatablesHelper.sanitizeEscape(row.strategyName !== 'email' ? `${data} (${row.strategyName.charAt(0).toUpperCase() + row.strategyName.slice(1)})` : data)
              },
              {
                data: 'rolename',
                searchable: false,
                orderable: false,
                render: data => userRoleService.formatTranslate(data)
              },
              {
                data: 'tags',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderTags
              },
              {
                data: 'createdAt',
                visible: false,
                className: 'never',
                searchable: false,
                render: (data, type, row) => $window.moment(data).format('D MMM YYYY')
              },
              {
                data: 'active',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderActiveInactive
              },
              {
                data: 'priorityAutoFilter',
                searchable: false,
                responsivePriority: 10001,
                render: datatablesHelper.renderYesNo
              },
              { data: 'completedSessionCount' },
              { // render action row menu
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  const canEditRoot = !row.isRoot || sessionState.isRoot
                  const template = `
                    <div class="dt-buttons btn-group dt-button-actions dropdown">
                      <button type="button" class="btn btn-defaut dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        ${canEditRoot ? `<li class="dt-button"><a class="change-state" data-id="${row.id}" data-to="${row.to}">{{'EditUserState' | translate}}</a></li>` : ''}
                        ${canEditRoot ? `<li class="dt-button"><a class="change-role" data-id="${row.id}">{{'EditUserRole' | translate}}</a></li>` : ''}
                        
                        ${canEditRoot ? '<li role="separator" class="divider"></li>' : ''}
                        <li class="dt-button"><a class="change-locale" data-id="${row.id}" data-locale="${row.locale}">{{'EditUserLocale' | translate}}</a></li>
                        <li class="dt-button"><a class="change-display-name" data-id="${row.id}">{{'EditUserChangeDisplayName' | translate}}</a></li>
                        ${canEditRoot && row.strategyName === 'email' || !emailIsValid(row.email) ? `<li class="dt-button"><a class="change-email" data-id="${row.id}">{{'EditUserChangeEmail' | translate}}</a></li>` : ''}
                        <li class="dt-button"><a class="change-internal-userid" data-id="${row.id}">{{'EditUserChangeInternalUserid' | translate}}</a></li>
                        
                        ${canEditRoot && row.strategyName === 'email' ? `<li role="separator" class="divider"></li><li class="dt-button"><a class="change-password" data-id="${row.id}">{{'EditUserChangePassword' | translate}}</a></li>` : ''}

                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a class="change-priority-auto-filter" data-id="${row.id}">{{'EditUserPriorityAutoFilter' | translate}}</a></li>

                        <li role="separator" class="divider"></li>
                        <li class="dt-button">
                          <a class="add-tags" data-id="${row.id}">
                            <span class="fa-stack-combine-custom">${tagService.tagPlusIcons()}{{'EditUserAddTags' | translate}}
                          </a>
                        </li>
                        <li class="dt-button">
                          <a class="remove-tags" data-id="${row.id}">${tagService.tagMinusIcons()}{{'EditUserRemoveTags' | translate}}
                          </a>
                        </li>

                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a class="show-completed-sessions" data-id="${row.id}">{{'ShowCompletedSessions' | translate}}</a></li>
                        <li class="dt-button"><a class="show-inprogress-sessions" data-id="${row.id}">{{'ShowInProgressSessions' | translate}}</a></li>
                        <li class="dt-button"><a class="show-time-usage" data-id="${row.id}">{{'ShowTimeUsage' | translate}}</a></li>
                        <li class="dt-button"><a class="show-priority-coverage" data-id="${row.id}">{{'ShowPriorityCoverage' | translate}}</a></li>

                        <li role="separator" class="divider"></li>
                        <li class="dt-button"><a class="show-invitation" data-id="${row.id}">{{'ShowInvitation' | translate}}</a></li>
                        ${canEditRoot ? `
                        <li role="separator" class="divider"></li>
                        <li class="dt-button">
                          <a class="delete-user" data-id="${row.id}">{{'Delete' | translate}}</a>
                        </li>` : ''
                    }
                      </ul>
                    </div>`
                  const compiled = ($interpolate(template)($scope))
                  return compiled
                }
              }
            ],
            order: [[0, 'desc']],
            select: true
          })
          datatablesHelper.createAndSetTable(datatableParams)

          $scope.table.on('select.dt.DT deselect.dt.DT', () => {
            const selected = $scope.table.rows({ selected: true })
            const data = selected.data().toArray()
            const hasSelected = data.length > 0
            const singleSelected = data.length === 1
            const underLimitSelected = data.length <= 200
            const canEdit = !data.some(one => one.isRoot && !sessionState.isRoot)
            const allEmailStrategyName = !data.some(one => one.strategyName !== 'email')
            const selectedOrgids = ids(selected, 'orgid')

            // disable buttons if connected user cannot edit some selected rows
            $scope.table.buttons(['.change-state', '.change-role']).enable(hasSelected && canEdit)
            // disable single edit buttons if more than 1 row selected
            $scope.table.buttons('.change-display-name').enable(hasSelected && singleSelected)
            $scope.table.buttons('.change-internal-userid').enable(hasSelected && singleSelected)
            // disable single edit buttons if connected user cannot edit some selected rows
            $scope.table.buttons(['.change-email', '.change-password']).enable(hasSelected && singleSelected && canEdit && allEmailStrategyName)
            // disable usage reports if too many selected
            $scope.table.buttons('.usage-report').enable(hasSelected && underLimitSelected)
            // disable usage reports if too many selected of the same organization
            $scope.table.buttons('.usage-report-priority-coverage').enable(hasSelected && underLimitSelected && selectedOrgids.length === 1)
          })
        }

        // ref for minification (?)
        const hideMultiRestoreButton = datatablesHelper.hideMultiRestoreButton

        const add = () => {
          editorAdd.title('<i class="fa fa-user-plus fa-fw"></i>&nbsp;' + $translate.instant('CreateUser'))
          if (organizations.length === 1) {
            editorAdd.field('orgDivider').hide()
            editorAdd.field('orgid').hide()
          }
          editorAdd.create({ buttons: $translate.instant('CreateTheUser') })
          if (editorAdd.createAnother) {
            editorAdd.set('createAnother', editorAdd.createAnother)
            editorAdd.set('passwordIsTemporary', editorAdd.passwordIsTemporary)
            if (editorAdd.showPassword) {
              editorAdd.field('password').toggleVisible()
            }
            editorAdd.set('tags[].tagid', editorAdd.tags)
            editorAdd.set('priorityAutoFilter', editorAdd.priorityAutoFilter)
            editorAdd.createAnother = false
          } else {
            editorAdd.field('password').toggleHide()
          }
        }

        const changeState = selected => {
          const selectedData = selected.data()
          const name = datatablesHelper.sanitizeEscape(selectedData[0].displayName)
          const title = selectedData.length === 1 ?
            $translate.instant('EditUserStateTitle', { name }) :
            $translate.instant('EditUsersStateTitle', { count: selectedData.length })
          editorActive.title(title)
          editorActive.edit(selected.indexes(), {
            buttons: $translate.instant('ChangeState')
          })
          hideMultiRestoreButton()
        }

        const deleteUser = selected => {
          const selectedData = selected.data()
          const name = datatablesHelper.sanitizeEscape(selectedData[0].displayName)
          const title = selectedData.length === 1 ?
            $translate.instant('DeleteUserTitle', { name }) :
            $translate.instant('DeleteUsersTitle', { count: selectedData.length })

          editorDelete.remove(selected.indexes(), true, {
            title,
            message: $translate.instant('DeleteUserMessage'),
            buttons: $translate.instant('DeleteOrDeactivateBtn')
          })
        }

        const changePriorityAutoFilter = selected => {
          const selectedData = selected.data()
          const name = datatablesHelper.sanitizeEscape(selectedData[0].displayName)
          const title = selectedData.length === 1 ?
            $translate.instant('EditUserPriorityAutoFilterTitle', { name }) :
            $translate.instant('EditUsersPriorityAutoFilterTitle', { count: selectedData.length })
          editorPriorityAutoFilter.title(title)
          editorPriorityAutoFilter.edit(selected.indexes(), {
            buttons: $translate.instant('ChangePriorityAutoFilter')
          })
          hideMultiRestoreButton()
        }

        const changeRole = selected => {
          const selectedData = selected.data()
          const name = datatablesHelper.sanitizeEscape(selectedData[0].displayName)
          const title = selectedData.length === 1 ?
            $translate.instant('EditUserRoleTitle', { name }) :
            $translate.instant('EditUsersRoleTitle', { count: selectedData.length })
          editorRole.title(title)
          editorRole.edit(selected.indexes(), {
            buttons: $translate.instant('ChangeRole')
          })
          hideMultiRestoreButton()
        }

        const changeLocale = selected => {
          const selectedData = selected.data()
          const name = datatablesHelper.sanitizeEscape(selectedData[0].displayName)
          const title = selectedData.length === 1 ?
            $translate.instant('EditUserLocaleTitle', { name }) :
            $translate.instant('EditUsersLocaleTitle', { count: selectedData.length })
          editorLocale.title(title)
          editorLocale.edit(selected.indexes(), {
            buttons: $translate.instant('ChangeLocale')
          })
          hideMultiRestoreButton()
        }

        const addTags = selected => {
          const selectedData = selected.data()
          const name = datatablesHelper.sanitizeEscape(selectedData[0].displayName)
          const title = selectedData.length === 1 ?
            $translate.instant('EditUserAddTagsTitle', { name }) :
            $translate.instant('EditUsersAddTagsTitle', { count: selectedData.length })
          editorAddTags.title(tagService.tagPlusIcons() + title)

          // get list of tags accessible for this user
          $http.get('/admin-api/tag?' + (ids(selected, 'orgid').length > 1 ? 'state=common&orgid=all' : 'orgid=' + selectedData[0].orgid) + '&format=datatables-option')
            .then((response) => {
              const tags = utilService.removeAlreadyOwnedChilds(selectedData, response.data, 'tags', 'tagid', 'value')
              editorAddTags.field('tags[].tagid').update(tags)
              editorAddTags.edit(selected.indexes(), false, { buttons: $translate.instant('AddTags') })
              //uncheck any tags
              editorAddTags.set('tags[].tagid', [])
              //show the edit form                    
              editorAddTags.open()
              hideMultiRestoreButton()
            })
        }

        const removeTags = selected => {
          const selectedData = selected.data()
          const name = datatablesHelper.sanitizeEscape(selectedData[0].displayName)
          const title = selectedData.length === 1 ?
            $translate.instant('EditUserRemoveTagsTitle', { name }) :
            $translate.instant('EditUsersRemoveTagsTitle', { count: selectedData.length })
          editorRemoveTags.title(tagService.tagMinusIcons() + title)

          // get list of tags accessible for this user
          $http.get('/admin-api/tag?' + (ids(selected, 'orgid').length > 1 ? 'state=common&orgid=all' : 'orgid=' + selectedData[0].orgid) + '&format=datatables-option')
            .then((response) => {
              const tags = utilService.removeNotOwnedChilds(selectedData, response.data, 'tags', 'tagid', 'value')
              editorRemoveTags.field('tags[].tagid').update(tags)
              editorRemoveTags.edit(selected.indexes(), false, { buttons: $translate.instant('RemoveTags') })
              //uncheck any tags
              editorRemoveTags.set('tags[].tagid', [])
              //show the edit form                    
              editorRemoveTags.open()
              hideMultiRestoreButton()
            })
        }

        const swal = $window.Swal.mixin({
          position: 'top',
          showClass: {
            popup: 'animate__animated animate__fadeInDown animate__faster'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp animate__faster'
          }
        })

        const changePassword = selected => {
          const indexes = selected.indexes()
          // single row editing only
          const user = selected.data()[0] // TODO: only one for now, modify for many selected
          const id = user.id
          const name = datatablesHelper.sanitizeEscape(user.displayName)
          const email = datatablesHelper.sanitizeEscape(user.email)

          swal.fire({
            title: $translate.instant('EditUserChangePasswordTitle', { name }),
            html: $translate.instant('EditUserChangePasswordMethodText'),
            icon: 'question',
            customClass: {
              confirmButton: 'fill-width',
              cancelButton: 'swal2-confirm fill-width'
            },
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: $translate.instant('EditUserChangePasswordMethodEmail'),
            cancelButtonText: $translate.instant('EditUserChangePasswordMethodManual'),
            showLoaderOnConfirm: true
          }).then(result => {
            // closed without pressing a button
            if (!result.isConfirmed && result.dismiss !== 'cancel') return
            // manual
            if (result.dismiss === 'cancel') return changePasswordManually()
            // email
            sendPasswordResetMessage()
          })

          const sendPasswordResetMessage = () => swal.fire({
            title: $translate.instant('EditUserChangePasswordTitle', { name }),
            html: $translate.instant('SendInfo'),
            icon: 'info',
            showConfirmButton: false,
            allowOutsideClick: () => !swal.isLoading(),
            didOpen: () => {
              swal.showLoading()
              swal.clickConfirm()
            },
            preConfirm: () => new Promise(resolve => {
              // DT style format
              passwordService.sendRecovery({ data: { [id]: { id, orgid: user.orgid, email } } })
                .then(success => resolve(success))
                .catch(error => {
                  swal.showValidationMessage(notificationHelper.errorMessageFrom(error))
                  resolve()
                })
            })
          }).then(result => {
            result.isConfirmed ? notificationHelper.success($translate.instant('PasswordRecoverySentFor', { email })) : null
          })


          const changePasswordManually = () => swal.fire({
            title: $translate.instant('EditUserChangePasswordTitle', { name }),
            html: $translate.instant('EditUserChangePasswordText'),
            icon: 'question',
            input: 'password',
            inputValidator: function (value) {
              return formService.validateFromAttributes(value, this.inputAttributes)
            },
            inputAttributes: { autocomplete: "new-password", minlength: passwordService.getMinLength(), required: true },
            showCloseButton: true,
            allowOutsideClick: false,
            confirmButtonText: $translate.instant('ChangePassword'),
            cancelButtonText: $translate.instant('Cancel'),
            showLoaderOnConfirm: true,
            preConfirm: password => new Promise(resolve => {
              // DT style format
              const users = { data: { [id]: { id, orgid: user.orgid, password, passwordIsTemporary: true } } }
              passwordService.changeByAdmin(users)
                .then(success => resolve(success))
                .catch(error => {
                  swal.showValidationMessage(notificationHelper.errorMessageFrom(error))
                  resolve()
                })
            })
          }).then(result => {
            return result.isConfirmed ? notificationHelper.success($translate.instant('PasswordChangedFor', { name })) : null
          })
        }

        const changeEmail = selected => {
          const user = selected.data()[0] // single row editing only
          const name = datatablesHelper.sanitizeEscape(user.displayName)
          const email = datatablesHelper.sanitizeEscape(user.email)

          swal.fire({
            title: $translate.instant('EditUserChangeEmailTitle', { name }),
            icon: 'question',
            input: 'email',
            inputValidator: function (value) {
              return formService.validateFromAttributes(value, this.inputAttributes, this.input)
            },
            inputValue: email,
            inputAttributes: { autocomplete: "new-email", maxlength: 255, required: true },
            showCloseButton: true,
            confirmButtonText: $translate.instant('ChangeEmail'),
            cancelButtonText: $translate.instant('Cancel'),
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: newEmail => {
              return new Promise(resolve => {
                editorChangeEmail
                  .edit(selected.indexes(), false)
                  .set('email', newEmail)
                  .submit(success => resolve(success), error => {
                    swal.showValidationMessage(notificationHelper.errorMessageFrom(error))
                    resolve()
                  })
              })
            }
          }).then(() => { })
        }

        const changeDisplayName = selected => {
          const user = selected.data()[0] // single row editing only
          const name = datatablesHelper.sanitizeEscape(user.displayName)

          swal.fire({
            title: $translate.instant('EditUserChangeDisplayNameTitle', { name }),
            html: $translate.instant('EditUserChangeDisplayNameText'),
            icon: 'question',
            input: 'text',
            inputValidator: function (value) {
              return formService.validateFromAttributes(value, this.inputAttributes)
            },
            inputValue: user.displayName,
            inputAttributes: { autocomplete: "new-display-name", minlength: 2, maxlength: 255, required: true },
            showCloseButton: true,
            confirmButtonText: $translate.instant('ChangeName'),
            cancelButtonText: $translate.instant('Cancel'),
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: newEmail => {
              return new Promise(resolve => {
                editorChangeDisplayName
                  .edit(selected.indexes(), false)
                  .set('displayName', newEmail)
                  .submit(success => resolve(success), error => {
                    swal.showValidationMessage(notificationHelper.errorMessageFrom(error))
                    resolve()
                  })
              })
            }
          }).then(() => { })
        }

        const changeInternalUserid = selected => {
          const user = selected.data()[0] // single row editing only
          const name = datatablesHelper.sanitizeEscape(user.displayName)

          swal.fire({
            title: $translate.instant('EditUserChangeInternalUseridTitle', { name }),
            icon: 'question',
            input: 'text',
            inputValidator: function (value) {
              return formService.validateFromAttributes(value, this.inputAttributes)
            },
            inputValue: datatablesHelper.sanitizeEscape(user.internalUserid),
            inputAttributes: {
              autocomplete: "new-internal-userid",
              minlength: 0,
              maxlength: 64,
              pattern: utilService.getInternalUseridPattern()
            },
            showCloseButton: true,
            confirmButtonText: $translate.instant('Save'),
            cancelButtonText: $translate.instant('Cancel'),
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: newInternalUserid => {
              return new Promise(resolve => {
                editorChangeInternalUserid
                  .edit(selected.indexes(), false)
                  .set('internalUserid', newInternalUserid)
                  .submit(success => resolve(success), error => {
                    swal.showValidationMessage(notificationHelper.errorMessageFrom(error))
                    resolve()
                  })
              })
            }
          }).then(() => { })
        }

        const showCompletedSessions = selected => $state.go('dashboardSessionCompleted', { search: `userid:${ids(selected).join()}` }, { reload: 'secure' })
        const showInProgressSessions = selected => $state.go('dashboardSessionInProgress', { search: `userid:${ids(selected).join()}` }, { reload: 'secure' })
        const showTimeUsage = selected => $state.go('dashboardSessionDailyTimeLog', { search: `userid:${ids(selected).join()}` }, { reload: 'secure' })
        const showPriorityCoverage = selected => $state.go('dashboardContentCoverage', { organization: `${ids(selected, 'orgid').join()}`, search: `userid:${ids(selected).join()}` }, { reload: 'secure' })

        const showInvitations = selected => $state.go('dashboardInviteStatus', { state: 'all', search: `codeid:${ids(selected, 'codeid').join()}` }, { reload: 'secure' })

        const ids = (selected, key = 'id') => utilService.ids(selected.data().toArray(), key)

        const doAction = (scope, fnAction) => {
          datatablesHelper.selectClosestRow($scope.table, datatablesHelper.getParentDiv(scope))
          fnAction($scope.table.rows({ selected: true }))
        }

        tableElement.on('click', 'a.change-state:not(.disabled)', e => doAction(e.target, changeState))
        tableElement.on('click', 'a.change-role:not(.disabled)', e => doAction(e.target, changeRole))

        tableElement.on('click', 'a.change-locale:not(.disabled)', e => doAction(e.target, changeLocale))
        tableElement.on('click', 'a.change-password:not(.disabled)', e => doAction(e.target, changePassword))
        tableElement.on('click', 'a.change-email:not(.disabled)', e => doAction(e.target, changeEmail))
        tableElement.on('click', 'a.change-display-name:not(.disabled)', e => doAction(e.target, changeDisplayName))
        tableElement.on('click', 'a.change-priority-auto-filter:not(.disabled)', e => doAction(e.target, changePriorityAutoFilter))
        tableElement.on('click', 'a.change-internal-userid:not(.disabled)', e => doAction(e.target, changeInternalUserid))

        tableElement.on('click', 'a.add-tags:not(.disabled)', e => doAction(e.target, addTags))
        tableElement.on('click', 'a.remove-tags:not(.disabled)', e => doAction(e.target, removeTags))

        tableElement.on('click', 'a.show-completed-sessions:not(.disabled)', e => doAction(e.target, showCompletedSessions))
        tableElement.on('click', 'a.show-inprogress-sessions:not(.disabled)', e => doAction(e.target, showInProgressSessions))
        tableElement.on('click', 'a.show-time-usage:not(.disabled)', e => doAction(e.target, showTimeUsage))
        tableElement.on('click', 'a.show-priority-coverage:not(.disabled)', e => doAction(e.target, showPriorityCoverage))

        tableElement.on('click', 'a.show-invitation:not(.disabled)', e => doAction(e.target, showInvitations))

        tableElement.on('click', 'a.delete-user:not(.disabled)', e => doAction(e.target, deleteUser))

        $scope.gotoPreviousState = () => $state.go($stateParams.previousState ? $stateParams.previousState : 'dashboard', {}, { reload: 'secure' })
      }
    ])