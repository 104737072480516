angular.module('mosaik.controllers')
  .controller('dashboardSessionDailyTimeLogController',
    ['$scope', 'sessionState', 'groups', 'tags', 'organizations', 'contents', 'categories', 'dashboardService', '$window', '$translate', '$timeout', 'datatablesHelper', 'utilService', 'timeService',
      function ($scope, sessionState, groups, tags, organizations, contents, categories, dashboardService, $window, $translate, $timeout, datatablesHelper, utilService, timeService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-session-daily-time-log'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/sessionDailyTimeLog',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.queryDefault = {
          dateRange: timeService.last30Days(),
          tagFilterMethod: 'or'
        }
        $scope.query = {
          dateRange: {
            start: null,
            end: null
          },
          tagFilterMethod: '',
          groups: [],
          contents: [],
          categories: [],
          tags: [],
          search: ''
        }
        // data
        $scope.ranges = timeService.timeLogRanges()
        $scope.tags = tags
        $scope.groups = groups
        const groupsAsObjects = utilService.mapObjectsByKey(groups)
        $scope.organizations = organizations
        $scope.contents = contents
        const contentsAsObjects = utilService.mapObjectsByKey(contents)
        $scope.categories = categories
        const categoriesAsObjects = utilService.mapObjectsByKey(categories)
        // DT table
        $scope.table

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', initializeTable))

        const exportOptions = {
          columns: sessionState.isRoot ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] : [0, 3, 4, 5, 6, 7, 8, 9]
        }
        const exportMessage = $translate.instant('TimeUsage')

        function initializeTable() {
          const datatableParams = angular.extend(datatablesHelper.getDatatablesParams({ 
            hiddenButtons: ['create', 'edit', 'remove'], 
            exportOptions, 
            exportMessage, 
            extraDTOptions: {
              pageLength: 10, // force default page length to reset to smallest possible 
              serverSide: true 
            },
            lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]]
          }), {
            columns: [
              { // Date logged
                data: 'date',
                render: datatablesHelper.renderDateHuman
              },
              { // Session orgname and internalOrgid
                visible: sessionState.isRoot,
                orderable: false,
                className: (!sessionState.isRoot ? 'never' : ''),
                data: (full, type) => !sessionState.isRoot ? '' : `${full.organization.name} <small class="text-id">${full.organization.internalOrgid}<small>`
              },
              { 
                data: 'userid',
                className: 'never',
                searchable: false
              },
              { // user display name
                orderable: false,
                data: full => datatablesHelper.sanitizeEscape(full.user.displayName)
              },
              { 
                data: 'sessionid',
                className: 'none',
                searchable: false
              },
              { 
                data: 'contentid',
                className: 'never',
                searchable: false
              },
              { // content title
                orderable: false,
                searchable: false,
                data: full => {
                  const content = contentsAsObjects[full.contentid] || {}
                  return content.titleText || '<i>Private content</i>'
                }
              },
              { // category name
                orderable: false,
                searchable: false,
                data: (full, type, meta) => {
                  const content = contentsAsObjects[full.contentid] || {}
                  return datatablesHelper.renderCategories(content.categoryids, type, full, meta, categoriesAsObjects)
                }
              },
              {
                data: 'ips',
                className: 'none',
                searchable: false
              },
              {
                data: 'timeInMillisecond', 
                orderable: false,
                searchable: false,
                render: datatablesHelper.renderMsToMin
              },
              {
                data: 'timeInMillisecond', 
                orderable: false,
                searchable: false,
                render: datatablesHelper.renderDurationHuman
              }
            ],
            ordering: false,
            select: false,
            footerCallback: function (full, data, start, end, display) {
              const api = this.api()
              // Total over all pages
              const totalms = api.column(9, { search: 'applied' }).data().reduce((a, b) => a + b, 0)
              // Update footer
              $(api.column(6).footer()).html(timeService.formatHoursMinutes(totalms))
            }
          })
          datatablesHelper.createAndSetTable(datatableParams)
        }
      }
    ])
