angular.module('mosaik.services')
  .factory('tagService',
    ['sessionStateService', 'labelService', 'utilService', 'httpService',
      function (sessionStateService, labelService, utilService, httpService) {
        const renderSpan = (tag, withTitle) => {
          const certificate = tag.isVisibleOnCertificate ? `<i class='fa fa-certificate fa-fw'></i>&nbsp;&nbsp;` : ''
          const private = tag.visibleOnlyByInternalOrgid ? `&nbsp;&nbsp;<small class='text-id'>${tag.visibleOnlyByInternalOrgid}</small>` : ''
          const style = labelService.getLabelCustomStyleAsText(tag.color, tag.isTextColorDefault)
          const title = tag.description && withTitle ? ` title='${tag.description}'` : ''
          return `<span class='label-mosaik label-mosaik-default'${title} style='${style}'>${certificate}${tag.name}</span>${private}`
        }
        const renderOption = tag => `<option value="${tag.id}" data-content="${renderSpan(tag, true)}"></option>`

        const self = {
          tags: (orgid, state) => sessionStateService.lastSession()
            .then(currentState => httpService.get(`/admin-api/tag?orgid=${orgid || currentState.orgidSelector}${state ? `&state=${state}` : ''}`, { createOptions: true, renderOption })),
          myTags: () => httpService.get('/api/user/me/allTags', { createOptions: true, renderOption }),
          mySubscribableTags: () => httpService.get('/api/user/me/subscribableTags', { createOptions: true, renderOption }),
          renderLabel: (label, value, i, row) => self.format(row),
          format: tag => renderSpan(tag, false),
          tagPlusIcons: () => `<span class="fa-stack-combine-custom">
              <i class="fa fa-tag fa-fw fa-stack-main-icon"></i>
              <i class="fa fa-plus fa-stack-tag-sign"></i></span>&nbsp;&nbsp;`,
          tagMinusIcons: () => `<span class="fa-stack-combine-custom">
              <i class="fa fa-tag fa-fw fa-stack-main-icon"></i>
              <i class="fa fa-minus fa-stack-tag-sign"></i></span>&nbsp;&nbsp;`
        }
        return self
      }]
  )