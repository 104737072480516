angular.module('mosaik.controllers')
  .controller('contentCoverageController',
    ['$scope', '$window', 'sessionState', 'organizations', '$translate', '$timeout', '$interpolate', 'datatablesHelper', 'utilService', 'timeService', 'tagService', 'contentService', 'stateService', 'certificateService',
      function ($scope, $window, sessionState, organizations, $translate, $timeout, $interpolate, datatablesHelper, utilService, timeService, tagService, contentService, stateService, certificateService) {
        // access rights
        $scope.canAccessDashboard = sessionState.canAccessDashboard
        $scope.isRoot = sessionState.isRoot
        // parameters
        const tableid = '#datatables-content-coverage'
        const tableElement = angular.element(tableid)
        const api = {
          url: '/admin-api/content-coverage',
          readType: 'POST'
        }
        $scope.queryHidden = {
          orgid: sessionState.orgidSelector
        }
        $scope.queryDefault = {
          dateRange: timeService.last12Months(),
          organization: sessionState.isRoot ? sessionState.orgid : undefined,
          tagFilterMethod: 'or',
          contents: [],
          includeNotConnectedUsers: false
        }
        $scope.query = {
          dateRange: {
            start: null,
            end: null
          },
          states: [],
          tagFilterMethod: '',
          tags: [],
          organization: '',
          contents: [],
          includeNotConnectedUsers: false
        }
        // data
        $scope.ranges = timeService.userActiveRanges()
        $scope.tags = [] // Only load tags accessible for the selected organization
        $scope.groups = []
        $scope.roles = []
        $scope.organizations = organizations
        $scope.contents = []
        let contentsAsObjects = {}
        $scope.categories = []
        let tagsAsObjects = {}
        $scope.states = stateService.getStates()
        // DT table
        $scope.table

        $timeout(() => datatablesHelper.init($scope, tableid, api, '', () => Promise.all([loadTags(), loadContent()]).then(() => initializeTable())))

        $scope.$on('filter-param-loaded', (event, data) => {
          if (data.key === 'organization') {
            loadTags()
          }
        })

        var exportOptions = {
          columns: [0, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        }
        var printMessage = $translate.instant('CompletedContent')

        function initializeTable() {
          var datatableParams = angular.extend(datatablesHelper.getDatatablesParams({
            exportOptions: exportOptions,
            exportMessage: printMessage,
            filteringInput: false,
            extraDTOptions: {
              serverSide: true
            }
          }), {
            columns: [
              { //0
                data: 'updatedAt',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderDateFromNow
              },
              { //1
                data: 'updatedAt',
                visible: false,
                className: 'never',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderDateTimeReport
              },
              { //2
                data: 'startedAt',
                responsivePriority: 10001,
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderDateTimeHuman
              },
              { //3
                data: 'completedAt',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderDateTimeHuman
              },
              { //4
                data: 'completedAt',
                visible: false,
                className: 'never',
                searchable: false,
                orderable: false,
                render: datatablesHelper.renderDateTimeReport
              },
              { //5
                data: 'sessionid',
                searchable: false,
                orderable: false,
                visible: false
              },
              { //6
                data: 'userid',
                orderable: false,
                visible: false
              },
              { //7
                data: 'internalUserid',
                orderable: false,
                visible: false
              },
              { //8
                data: 'displayName',
                orderable: false,
                render: datatablesHelper.sanitizeEscape
              },
              { //9
                data: 'email',
                orderable: false,
                render: datatablesHelper.sanitizeEscape
              },
              { //10
                data: 'contentid',
                searchable: false,
                orderable: false,
                visible: false
              },
              { //11
                data: 'locale',
                searchable: false,
                orderable: false,
                visible: false
              },
              { //12
                // contentTitle
                data: row => {
                  const content = contentsAsObjects[row.contentid]
                  if (!content) {
                    // unknown content (the admin has no access to this content, can be private content from another org)
                    return '<i class="text-id">' + $translate.instant('ContentFromAnotherOrg', { contentid: row.contentid }) + '</i>'
                  }
                  return content.titleText
                },
                searchable: false,
                orderable: false
              },
              { // 13
                // state
                data: row => {
                  if (row.completed === 1) {
                    return (row.toRedoOnce === 1 || row.toRedoEveryInMonths === 1) ? stateService.format('ToRedo') : stateService.format('Completed')
                  }
                  return (row.inProgress === 1) ? stateService.format('InProgress') : stateService.format('NotStarted')
                },
                searchable: false,
                orderable: false
              },
              { //14
                data: 'scoreScaled',
                render: data => data !== null ? Math.round(data * 100) : '',
                searchable: false,
                orderable: false,
                visible: false
              },
              { //15
                data: 'timeInMillisecond',
                render: data => !data ? '' : (data / 1000 / 60).toFixed(1),
                searchable: false,
                orderable: false,
                visible: false
              },
              { //16
                // actions menu: attestation click, 
                defaultContent: '',
                searchable: false,
                orderable: false,
                data: (row, type) => {
                  const content = contentsAsObjects[row.contentid]
                  if (type === 'export' || !row.completed || !content || !content.givesCertificate) {
                    return ''
                  }

                  const template = `
                    <div class="dt-buttons btn-group dt-button-actions dropdown">
                      <button type="button" class="btn btn-defaut dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-h"></i> 
                      </button>
                      <ul class="dropdown-menu dropdown-menu-right">
                      ${content.givesCertificate ? `
                        <li class="dt-button">
                          <a id="show-certificate" data-id="${row.id}">${$translate.instant('ShowCertificate', { count: 1 })}
                          </a>
                        </li>
                        ` : ''}
                      </ul>
                    </div>`
                  const compiled = ($interpolate(template)($scope))
                  return compiled
                }
              }
            ],
            select: false
          })
          datatablesHelper.createAndSetTable(datatableParams)
        }

        function loadTags() {
          const orgid = $scope.query.organization || sessionState.orgid
          return tagService.tags(orgid, 'all')
            .then(function (tags) {
              $scope.tags = tags
              tagsAsObjects = utilService.mapObjectsByKey(tags)
              $timeout(() => $scope.$broadcast('picker-dataset-change', 'tags'))
            })
        }

        function loadContent() {
          return contentService.contentsForList({ orgid: $scope.query.organization || sessionState.orgid, activeState: 'active', priorityState: 'priority' })
            .then(function (contents) {
              $scope.contents = contents
              contentsAsObjects = utilService.mapObjectsByKey(contents)
              $timeout(() => $scope.$broadcast('picker-dataset-change', 'contents'))
            })
        }

        function downloadCertificate(selected) {
          const data = selected.data()
          const userids = utilService.ids(data.toArray(), 'userid').join()
          const sessionids = utilService.ids(data.toArray(), 'sessionid').join()

          certificateService.get(userids, sessionids)
        }

        function doAction(scope, fnAction) {
          datatablesHelper.selectClosestRow($scope.table, datatablesHelper.getParentDiv(scope))
          fnAction($scope.table.rows({ selected: true }))
        }

        function reloadOnValidEvent(data, key, expectedKey) {
          if (key === expectedKey) {
            loadTags()
            loadContent()
          }
        }

        $scope.$on('coverage-include-not-connected-users', (event, state) => {
          // nothing to do yet
        })

        tableElement.on('click', '#show-certificate:not(.disabled)', (event) => doAction(event.target, downloadCertificate))

        $scope.$on('select-modified', (event, data) => reloadOnValidEvent(data, data.id, 'organizations-selectpicker'))
        $scope.$on('filter-param-clear', (event, data) => reloadOnValidEvent(data, data.key, 'organization'))
        $scope.$on('filter-param-loaded', (event, data) => reloadOnValidEvent(data, data.key, 'organization'))
      }
    ])
